import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import FindAddress from "./FindAddress";
import { hideAlert, showAlert } from "store/alertReducer";
import { showLoading, hideLoading } from "store/loadingReducer";
import Button from "components/button/Button";
import AdminConfirmButton from "components/button/AdminConfirmButton";
import InputFile from "components/common/InputFile";
import * as APIS from "libs/apis";
import { formatPhone, formatTime, numFormat, objToQueryStr } from "libs/utils";
import {
  Grid,
  GridColumn,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import {
  Scheduler,
  MonthView,
  SchedulerViewItem,
  SchedulerItem,
  SchedulerViewSlot,
} from "@progress/kendo-react-scheduler";
import { Checkbox } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ExampleShopImage from "assets/images/example_shop_image.jpg";
import consts from "libs/consts";

const Wrap = styled.div`
  .k-animation-container {
    width: 100%;
    height: 830px;
    overflow-y: scroll;
  }
  .k-tabstrip-items .k-link {
    font-family: "NotoSansKR-Bold";
    font-size: 16px;
  }
`;

const Table = styled.table`
  width: 100%;
  height: 100%;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  border-collapse: collapse;
`;

const Th = styled.th`
  background: #f2f4f6;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #636a75;
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
`;

const Td = styled.td`
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
  padding: 10px;
`;

const CenterTd = styled.td`
  border: 1px solid #e2e5e8;
  border-left: none;
  padding: 10px;
  text-align: center;
`;

const Text = styled.div`
  align-self: center;
  font-weight: bold;
  font-family: NotoSansKR-Bold;
  font-size: 16px;
`;

const InputBox = styled.input`
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 3px;
  padding: 0 10px;
  min-height: 20px;
`;

const ShopImageWrap = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  margin-bottom: 10px;

  .bold {
    font-weight: bold;
  }
`;

const ImageFrame = styled.img`
  width: 140px;
  height: 140px;
  margin-right: 15px;
  border: 1px solid rgba(0, 0, 0, 0.23);
`;

const NoImage = styled.div`
  width: 140px;
  height: 140px;
  margin-right: 15px;
  border: 1px solid rgba(0, 0, 0, 0.23);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImageGuide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 10px;
`;

const UnderlineBtn = styled.button`
  color: navy;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
`;

const week = [
  { label: "월", value: "1" },
  { label: "화", value: "2" },
  { label: "수", value: "3" },
  { label: "목", value: "4" },
  { label: "금", value: "5" },
  { label: "토", value: "6" },
  { label: "일", value: "0" },
];

const holidayRepetitionData = [
  { name: "매년", value: "annually" },
  { name: "일회", value: "once" },
];

// 월 목록 생성
const getMonth = () => {
  let month = [];
  for (let i = 1; i <= 12; i++) {
    month.push({ label: `${i}월`, value: i < 10 ? `0${i}` : `${i}` });
  }
  return month;
};
// 일 목록 생성
const getDays = () => {
  let days = [];
  for (let i = 1; i <= 31; i++) {
    days.push({ label: `${i}일`, value: i < 10 ? `0${i}` : `${i}` });
  }
  return days;
};
// 시 목록 생성
const getHours = () => {
  let hours = [];
  for (let i = 5; i <= 23; i++) {
    hours.push({
      name: i < 10 ? `0${i}시` : `${i}시`,
      value: i < 10 ? `0${i}:00` : `${i}:00`,
    });
  }
  return hours;
};

const StoreManagement = () => {
  const dispatch = useDispatch();
  const authReducer = useSelector((s) => s.auth);
  let { screenType } = authReducer;
  const isShop = screenType === "SHOP";
  const [shop, setShop] = useState({});
  const [banks, setBanks] = useState([]);
  const [selected, setSelected] = useState(0);
  const [defaultHoursFrom, setDefaultHoursFrom] = useState({
    name: "10시",
    value: "10:00",
  }); //기본근무시간 시작
  const [defaultHoursTo, setDefaultHoursTo] = useState({
    name: "19시",
    value: "19:00",
  }); //기본근무시간 종료
  const [date, setDate] = useState(new Date());
  const [calendarData, setCalendarData] = useState([]);
  const [businessDayCheck, setBusinessDayCheck] = useState([]); //기본근무시간 날짜 선택
  const [holidaysRepetition, setHolidaysRepetition] = useState({
    name: "일회",
    value: "once",
  }); //휴업 반복
  const [onceHolidayFrom, setOnceHolidayFrom] = useState(new Date()); //휴업 일회 시작 날짜
  const [onceHolidayTo, setOnceHolidayTo] = useState(new Date()); //휴업 일회 종료 날짜
  const [holidayStartMonth, setHolidayStartMonth] = useState(); //휴업 매년 시작 달
  const [holidayStartDay, setHolidayStartDay] = useState(); //휴업 매년 시작 날
  const [holidayEndMonth, setHolidayEndMonth] = useState(); //휴업 매년 종료 달
  const [holidayEndDay, setHolidayEndDay] = useState(); //휴업 매년 종료 날
  const [businessDayFrom, setBusinessDayFrom] = useState(new Date()); //영업시간 조정 시작날짜
  const [businessDayTo, setBusinessDayTo] = useState(new Date()); //영업시간 조정 종료날짜
  const [businessStartHour, setBusinessStartHour] = useState(); //영업 시간 시작 시간
  const [businessEndHour, setBusinessEndHour] = useState(); //영업 시간 종료 시간
  const [show, setShow] = useState(false);
  const [appraisalVisitorList, setAppraisalVisitorList] = useState([]);
  const [goodsTransVisitorList, setGoodsTransVisitorList] = useState([]);
  const [safeTradeVisitorList, setSafeTradeVisitorList] = useState([]);
  const [cancelHoliday, setCancelHoliday] = useState(false);
  const [image, setImage] = useState(null);

  let days = getDays();
  let month = getMonth();
  let hours = getHours();

  const handleSelectTab = (e) => {
    setSelected(e.selected);
  };

  const handleSaveHoliday = () => {
    setShow(!show);
  };

  const showImage = () => {
    const imageWidth = 720;
    const imageHeight = 720;

    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    const left = (screenWidth - imageWidth) / 2;
    const top = (screenHeight - imageHeight) / 2;

    window.open(
      ExampleShopImage,
      "_blank",
      `width=${imageWidth},height=${imageHeight},top=${top},left=${left}`
    );
  };

  const handleChangeImage = (file) => {
    console.log(file);
    if (file) {
      const fileType = file.name.split(".").pop().toLowerCase();
      if (!["gif", "jpg", "jpeg", "png"].includes(fileType)) {
        dispatch(
          showAlert({
            message: "이미지파일(.jpg, .png, .jpeg)만 업로드 가능합니다.",
          })
        );
        return;
      }

      if (file.size > 100 * 1024) {
        dispatch(
          showAlert({
            message: `첨부 가능한 파일 용량을 초과하였습니다.\n100kb 이하의 파일을 올려주세요.`,
          })
        );
        return;
      }

      const f = new FileReader();
      f.onload = () => {
        const img = new Image();
        img.src = f.result;

        img.onload = () => {
          // 비율 체크
          if (img.width !== img.height) {
            dispatch(showAlert({ message: "이미지 비율이 1:1이 아닙니다." }));
            return;
          }

          file.uri = f.result;
          setImage(file);
        };
      };

      f.readAsDataURL(file);
    }
  };

  const cellRender = (cell, props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    if (
      props.field === "account" ||
      props.field === "name" ||
      props.field === "phone" ||
      props.field === "managerGroup" ||
      props.field === "createdAt" ||
      props.field === "status"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}>
          {props.dataItem[props.field]}
        </td>
      );
    }
    return cell;
  };
  const onLoad = (month) => {
    const param = { workYm: month };
    if (selected === 1 && month) {
      APIS.getShopManagementDay(objToQueryStr(param)).then(
        ({ data: { success, data, message } }) => {
          if (success) {
            const { businessHoursList, holidaysList } = data;
            const newArray = [];
            const last = new Date(date);
            last.setMonth(last.getMonth() + 1);
            last.setDate(0);
            for (let i = 1; i <= last.getDate(); i++) {
              const day = new Date(date);
              day.setDate(i);
              const holidayCheck = holidaysList.find(
                (item) => item.manageDate === formatTime(day)
              );
              newArray.push({
                title: holidayCheck
                  ? holidayCheck?.isFullDayOff === false
                    ? `${holidayCheck?.openHr} ~ ${holidayCheck?.closeHr}`
                    : "휴업"
                  : businessHoursList.length > 0
                  ? businessHoursList.find(
                      (item) => item.dayWeek === String(day.getDay())
                    )?.isFullDayOff === false
                    ? `${
                        businessHoursList.find(
                          (item) => item.dayWeek === String(day.getDay())
                        )?.openHr
                      } ~ ${
                        businessHoursList.find(
                          (item) => item.dayWeek === String(day.getDay())
                        )?.closeHr
                      } `
                    : "휴업"
                  : "",
                start: new Date(`${formatTime(day)}T10:00:00Z`),
                end: new Date(`${formatTime(day)}T19:00:59Z`),
                isHoliday: holidayCheck ? true : false,
              });
            }
            setCalendarData([]);
            setTimeout(() => {
              setCalendarData(newArray);
            }, 100);
          } else {
            dispatch(showAlert({ message: message }));
          }
        }
      );
    }
  };

  const handleClickFindAddress = () => {
    dispatch(
      showAlert({
        message: (
          <FindAddress
            callback={({ postCode, address, show }) => {
              dispatch(hideAlert());
              setShop((shop) => ({
                ...shop,
                shopPostCode: postCode,
                shopAddress: address,
                shopAddressDetail: "",
              }));
              show();
            }}
          />
        ),
      })
    );
  };

  const onSaveAdmin = async (adminPassword) => {
    const data = {
      shopBankCode: shop.shopBankCode,
      shopBankAccountNumber: shop.shopBankAccountNumber,
      shopBusinessNumber: shop.shopBusinessNumber,
      shopPhone: shop.shopPhone,
      shopFax: shop.shopFax,
      shopAddress: shop.shopAddress,
      shopAddressDetail: shop.shopAddressDetail,
      shopPostCode: shop.shopPostCode,
      managerPassword: adminPassword,
    };

    const json = JSON.stringify(data);
    const blob = new Blob([json], {
      type: "application/json",
    });

    if (shop?.imagePath === null && !image) {
      dispatch(showAlert({ message: "대리점 이미지를 등록해 주세요." }));
      return;
    }

    const formData = new FormData();
    formData.append("dto", blob);
    formData.append(`image`, image);

    dispatch(showLoading());
    APIS.saveShop(formData)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          dispatch(
            showAlert({
              message: "대리점 수정이 완료되었습니다.",
              onClick: () => {
                onLoad();
              },
            })
          );
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  const getVisitorList = () => {
    if (holidaysRepetition.value === "annually") {
      if (
        !holidayStartMonth?.value ||
        !holidayStartDay?.value ||
        !holidayEndMonth?.value ||
        !holidayEndDay?.value
      ) {
        return dispatch(
          showAlert({
            message: "휴뮤일의 시작일과 종료일을 선택하여 주세요.",
          })
        );
      } else if (
        parseInt(holidayStartMonth.value) > parseInt(holidayEndMonth.value)
      ) {
        return dispatch(
          showAlert({
            message:
              "종료시 해당하는 달을 시작시의 해당하는 달보다 늦게 설정해주세요. ",
          })
        );
      } else if (
        parseInt(holidayStartMonth.value) === parseInt(holidayEndMonth.value) &&
        parseInt(holidayStartDay.value) > parseInt(holidayEndDay.value)
      ) {
        return dispatch(
          showAlert({
            message:
              "종료시 해당하는 일을 시작시의 해당하는 일보다 늦게 설정해주세요. ",
          })
        );
      }
    } else {
      if (new Date(formatTime()) > new Date(formatTime(onceHolidayFrom))) {
        return dispatch(
          showAlert({
            message:
              "시작일에 해당하는 날짜를 오늘 날짜 이후로 지정하여 주세요.",
          })
        );
      } else if (
        new Date(formatTime(onceHolidayFrom)) >
        new Date(formatTime(onceHolidayTo))
      ) {
        return dispatch(
          showAlert({
            message:
              "종료시 해당하는 날짜를 시작시의 해당하는 날짜보다 늦게 설정해주세요. ",
          })
        );
      }
    }
    dispatch(showLoading());
    const param = {
      repetition: holidaysRepetition.value,
      durationFrom:
        holidaysRepetition.value === "once"
          ? formatTime(onceHolidayFrom)
          : `9999-${holidayStartMonth.value}-${holidayStartDay.value}`,
      durationTo:
        holidaysRepetition.value === "once"
          ? formatTime(onceHolidayTo)
          : `9999-${holidayEndMonth.value}-${holidayEndDay.value}`,
      isFullDayOff: true,
    };
    APIS.getVisitorList(objToQueryStr(param))
      .then(({ data: { success, data, message } }) => {
        if (success) {
          if (
            (data.appraisalVisitUsers.length > 0 ||
              data.goodsTransVisitUsers.length > 0 ||
              data.safeTradeSellVisitUsers.length > 0) &&
            cancelHoliday === false
          ) {
            setAppraisalVisitorList(
              data.appraisalVisitUsers.map((item) => {
                return {
                  ...item,
                  userName: `${item.userName}(${item.userId})`,
                  phone: formatPhone(item.phone),
                };
              })
            );
            setGoodsTransVisitorList(
              data.goodsTransVisitUsers.map((item) => {
                return {
                  ...item,
                  userName: `${item.userName}(${item.userId})`,
                  phone: formatPhone(item.phone),
                };
              })
            );
            setSafeTradeVisitorList(
              data.safeTradeSellVisitUsers.map((item) => {
                return {
                  ...item,
                  userName: `${item.userName}(${item.userId})`,
                  phone: formatPhone(item.phone),
                };
              })
            );
            handleSaveHoliday();
          } else {
            dispatch(
              showAlert({
                message: `${
                  holidaysRepetition.value === "once"
                    ? formatTime(onceHolidayFrom)
                    : `매년 ${holidayStartMonth.value}-${holidayStartDay.value}`
                } ~ ${
                  holidaysRepetition.value === "once"
                    ? formatTime(onceHolidayTo)
                    : `${holidayEndMonth.value}-${holidayEndDay.value}`
                }
                휴무일을 ${
                  cancelHoliday === true ? "취소" : "저장"
                }하시겠습니까?`,
                cancel: true,
                onClick: () => {
                  handleClickHolidaysSave("holiday");
                },
              })
            );
          }
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  useEffect(() => {
    // onLoad();
    dispatch(showLoading());
    const detailApi = isShop ? APIS.getShopDetail() : APIS.getBizShopDetail();
    const detailShop = detailApi.then(
      ({ data: { success, data, message } }) => {
        if (success) {
          setShop({
            ...data,
            managerPassword: "",
            managerList: data.managerList.map((item) => {
              return {
                ...item,
                phone: formatPhone(item.phone),
                createdAt: formatTime(item.createdAt),
                status: item.status === "ACTIVE" ? "활성" : "비활성",
              };
            }),
          });
        } else {
          dispatch(showAlert({ message: message }));
        }
      }
    );
    const listApi = isShop ? APIS.getBankList() : APIS.getBizBankList();
    const bankList = listApi.then(({ data: { success, message, data } }) => {
      if (success) {
        setBanks(data);
      } else {
        dispatch(showAlert({ message: message }));
      }
    });
    Promise.all([detailShop, bankList]).finally(() => {
      dispatch(hideLoading());
    });
  }, []);

  useEffect(() => {
    const getMonthLength = (date.getMonth() + 1).toString().length;
    const month = `${date.getFullYear()}-${
      getMonthLength === 1 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
    }`;
    onLoad(month);
  }, [date, selected]);

  useEffect(() => {
    if (holidaysRepetition.value === "once") {
      setOnceHolidayFrom(new Date());
      setOnceHolidayTo(new Date());
    } else {
      setHolidayStartMonth();
      setHolidayStartDay();
      setHolidayEndMonth();
      setHolidayEndDay();
    }
  }, [holidaysRepetition]);

  useEffect(() => {
    setOnceHolidayTo(new Date(onceHolidayFrom));
  }, [onceHolidayFrom]);

  useEffect(() => {
    setBusinessDayTo(new Date(businessDayFrom));
  }, [businessDayFrom]);

  const handleChange = (event) => {
    if (event.target.name === "defaultHoursFrom") {
      setDefaultHoursFrom(event.target.value);
    } else if (event.target.name === "defaultHoursTo") {
      setDefaultHoursTo(event.target.value);
    } else if (event.target.name === "holidaysRepetition") {
      setHolidaysRepetition(event.target.value);
    } else if (event.target.name === "holidayStartMonth") {
      setHolidayStartMonth(event.target.value);
    } else if (event.target.name === "holidayStartDay") {
      setHolidayStartDay(event.target.value);
    } else if (event.target.name === "holidayEndMonth") {
      setHolidayEndMonth(event.target.value);
    } else if (event.target.name === "holidayEndDay") {
      setHolidayEndDay(event.target.value);
    } else if (event.target.name === "businessStartHour") {
      setBusinessStartHour(event.target.value);
    } else if (event.target.name === "businessEndHour") {
      setBusinessEndHour(event.target.value);
    }
  };

  const handleDateChange = (e) => {
    if (e.value?._localDate) {
      setDate(e.value._localDate);
    } else {
      setDate(e.value);
    }
  };

  //기본 영업 시간 설정
  const handleClickBusinessHoursSave = () => {
    if (businessDayCheck.length === 0) {
      return dispatch(showAlert({ message: "근무 날짜를 선택하여 주세요." }));
    } else if (
      parseInt(defaultHoursFrom.value) >= parseInt(defaultHoursTo.value)
    ) {
      return dispatch(
        showAlert({
          message: "근무 종료시간을 시작시간보다 늦게 설정해주세요.",
        })
      );
    }
    const data = {
      hoursList: businessDayCheck.map((item) => {
        return {
          dayWeek: item,
          openHr: defaultHoursFrom.value,
          closeHr: defaultHoursTo.value,
          isFullDayOff: false,
        };
      }),
    };
    dispatch(showLoading());
    APIS.postBusinessHourSave(data)
      .then(({ data: { data, message, success } }) => {
        if (success) {
          dispatch(
            showAlert({
              message: "기본 영업시간이 설정되었습니다.",
              onClick: () => {
                const getMonthLength = (date.getMonth() + 1).toString().length;
                const month = `${date.getFullYear()}-${
                  getMonthLength === 1
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1
                }`;
                onLoad(month);
              },
            })
          );
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((error) =>
        dispatch(showAlert({ message: error.response.data.message }))
      )
      .finally(() => dispatch(hideLoading()));
  };
  //휴무일 지정
  const handleClickHolidaysSave = (dataType) => {
    if (dataType !== "holiday") {
      if (new Date(formatTime()) > new Date(formatTime(businessDayFrom))) {
        return dispatch(
          showAlert({
            message:
              "시작일에 해당하는 날짜를 오늘 날짜 이후로 지정하여 주세요.",
          })
        );
      } else if (
        new Date(formatTime(businessDayFrom)) >
        new Date(formatTime(businessDayTo))
      ) {
        return dispatch(
          showAlert({
            message:
              "종료시 해당하는 날짜를 시작시의 해당하는 날짜보다 늦게 설정해주세요. ",
          })
        );
      } else if (!businessStartHour?.value || !businessEndHour?.value) {
        return dispatch(
          showAlert({
            message: "영업시간을 설정해주세요. ",
          })
        );
      } else if (
        parseInt(businessStartHour.value) >= parseInt(businessEndHour.value)
      ) {
        return dispatch(
          showAlert({
            message: "영업시간 종료시간을 시작시간보다 늦게 설정해주세요. ",
          })
        );
      }
    }

    let data = {};
    dataType === "holiday"
      ? (data = {
          repetition: holidaysRepetition.value,
          durationFrom:
            holidaysRepetition.value === "once"
              ? formatTime(onceHolidayFrom)
              : `9999-${holidayStartMonth.value}-${holidayStartDay.value}`,
          durationTo:
            holidaysRepetition.value === "once"
              ? formatTime(onceHolidayTo)
              : `9999-${holidayEndMonth.value}-${holidayEndDay.value}`,
          isFullDayOff: true,
          isCancel: cancelHoliday,
        })
      : (data = {
          repetition: "partTime",
          durationFrom: formatTime(businessDayFrom),
          durationTo: formatTime(businessDayTo),
          openHr: businessStartHour.value,
          closeHr: businessEndHour.value,
          isFullDayOff: false,
          isCancel: false,
        });

    dispatch(showLoading());
    APIS.postHolidaysSave(data)
      .then(({ data: { message, success } }) => {
        if (success) {
          dispatch(
            showAlert({
              message:
                dataType === "holiday" ? message : "영업시간이 조정되었습니다.",
              onClick: () => {
                const getMonthLength = (date.getMonth() + 1).toString().length;
                const month = `${date.getFullYear()}-${
                  getMonthLength === 1
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1
                }`;
                onLoad(month);
                if (show === true) {
                  handleSaveHoliday();
                }
              },
            })
          );
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((error) =>
        dispatch(showAlert({ message: error.response.data.message }))
      )
      .finally(() => dispatch(hideLoading()));
  };

  const CustomItem = (props) => {
    return (
      <SchedulerItem
        {...props}
        style={{
          ...props.style,
          backgroundColor:
            props.title === "휴업"
              ? "#ff7c73"
              : props.dataItem.isHoliday
              ? "#ff9d5b"
              : "white",
          color: props.title === "휴업" ? "white" : "black",
          zIndex: -1,
        }}>
        {props.title}
      </SchedulerItem>
    );
  };
  const CustomViewSlot = (props) => {
    return (
      <SchedulerViewSlot
        {...props}
        style={{
          ...props.style,
          cursor: "pointer",
          backgroundColor:
            props.selected === true ? "rgba(255, 99, 88, 0.25)" : "",
        }}
        onClick={(e) => {
          setBusinessDayFrom(new Date(e.target.props.start));
          setOnceHolidayFrom(new Date(e.target.props.start));
        }}
      />
    );
  };

  const popupCellRender = (cell, props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    if (
      props.field === "number" ||
      props.field === "goodsName" ||
      props.field === "userName" ||
      props.field === "phone" ||
      props.field === "no" ||
      props.field === "qty" ||
      props.field === "goldOrSilver" ||
      props.field === "visitDate"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}>
          {props.dataItem[props.field]}
        </td>
      );
    }
    return cell;
  };
  const defaultMonthItem = {
    label: "월",
  };

  const defaultDayItem = {
    label: "일",
  };
  const defaultTimeItem = {
    name: "시",
  };
  const {
    createdAt = "",
    shopBankCode,
    shopBankAccountNumber,
    shopPhone,
    shopPostCode,
    shopAddress,
    shopAddressDetail,
    shopBusinessNumber,
    shopFax,
    shopCode,
    managerList,
    paymentKrw,
    allowKrw,
  } = shop;

  return (
    <div style={{ padding: "10px 20px 0px 20px" }}>
      <Wrap style={{ marginTop: 10 }}>
        <TabStrip selected={selected} onSelect={handleSelectTab}>
          <TabStripTab
            title="상세정보"
            style={{
              width: "100%",
              height: "100%",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
              <Text>대리점 정보</Text>
              <div>
                <AdminConfirmButton
                  title="관리자 메모 등록"
                  callback={onSaveAdmin}
                />
              </div>
            </div>
            <ShopImageWrap>
              {image?.uri || shop?.imagePath ? (
                <ImageFrame
                  src={
                    image?.uri
                      ? image?.uri
                      : consts.s3BaseUrl + "/" + shop?.imagePath
                  }
                  alt="대리점 이미지"
                />
              ) : (
                <NoImage>
                  📷
                  <div>이미지를 등록해주세요</div>
                </NoImage>
              )}

              <div>
                <InputFile
                  accept="image/*"
                  label="등록"
                  callback={handleChangeImage}
                  buttonStyles={`width: 42px`}
                />
                <ImageGuide>
                  <div className="bold">등록 가이드</div>
                  <div className="bold">- 지원 확장자: .jpg .png .jpeg</div>
                  <div>- 대리점 이미지는 1:1 비율로 업로드 해주세요.</div>
                  <div>- 대리점 전경이 잘 나오도록 업로드 해주세요.</div>
                  <UnderlineBtn onClick={showImage}>
                    대리점 이미지 예시 보기
                  </UnderlineBtn>
                </ImageGuide>
              </div>
            </ShopImageWrap>
            <Table>
              <colgroup>
                <col width="7%" />
                <col width="20%" />
                <col width="7%" />
                <col width="20%" />
              </colgroup>
              <tbody>
                <tr>
                  <Th>등록일</Th>
                  <Td>{formatTime(createdAt) || "-"}</Td>
                  <Th>가맹점 코드</Th>
                  <Td>{shopCode || "-"}</Td>
                </tr>
                <tr>
                  <Th>사업자 번호</Th>
                  <Td>
                    <InputBox
                      value={shopBusinessNumber || ""}
                      onChange={({ target: { value } }) => {
                        setShop((shop) => ({
                          ...shop,
                          shopBusinessNumber: value,
                        }));
                      }}
                    />
                  </Td>
                  <Th>정산계좌</Th>
                  <Td>
                    <select
                      style={{
                        minWidth: 100,
                        fontSize: 16,
                      }}
                      value={shopBankCode}
                      onChange={(e) => {
                        setShop((shop) => ({
                          ...shop,
                          shopBankCode: e.target.value,
                        }));
                      }}>
                      {banks.map(({ code, name }, i) => {
                        return (
                          <option key={i.toString()} value={code}>
                            {name}
                          </option>
                        );
                      })}
                    </select>
                    <input
                      style={{
                        minWidth: 200,
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid rgba(0,0,0,0.23)",
                        borderRadius: 3,
                        padding: "0 10px",
                        minHeight: 20,
                        marginLeft: 10,
                      }}
                      value={shopBankAccountNumber || ""}
                      onChange={({ target: { value } }) => {
                        setShop((shop) => ({
                          ...shop,
                          shopBankAccountNumber: value,
                        }));
                      }}></input>
                  </Td>
                </tr>
                <tr>
                  <Th>지급 보증금</Th>
                  <Td>{numFormat(paymentKrw) || "-"}</Td>
                  <Th>거래 허용금</Th>
                  <Td>{numFormat(allowKrw) || "-"}</Td>
                </tr>
                <tr>
                  <Th>전화번호</Th>
                  <Td>
                    <InputBox
                      value={shopPhone || ""}
                      onChange={({ target: { value } }) => {
                        setShop((shop) => ({
                          ...shop,
                          shopPhone: value,
                        }));
                      }}
                    />
                  </Td>
                  <Th>팩스</Th>
                  <Td>
                    <InputBox
                      value={shopFax || ""}
                      onChange={({ target: { value } }) => {
                        setShop((shop) => ({
                          ...shop,
                          shopFax: value,
                        }));
                      }}
                    />
                  </Td>
                </tr>
                <tr>
                  <Th>주소</Th>
                  <Td colSpan={4}>
                    <div style={{ display: "flex" }}>
                      <Button label="주소찾기" onClick={handleClickFindAddress}>
                        주소찾기
                      </Button>
                      <span
                        style={{
                          minWidth: 200,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid rgba(0,0,0,0.23)",
                          borderRadius: 3,
                          padding: "0 10px",
                          minHeight: 20,
                          marginLeft: 10,
                          cursor: "pointer",
                        }}
                        onClick={handleClickFindAddress}>
                        {shopPostCode && `(${shopPostCode}) `}
                        {shopAddress}
                      </span>
                      <input
                        style={{
                          flex: 1,
                          border: "1px solid rgba(0,0,0,0.23)",
                          borderRadius: 3,
                          marginLeft: 10,
                          padding: 5,
                          minHeight: 20,
                        }}
                        value={shopAddressDetail || ""}
                        onChange={({ target: { value } }) => {
                          setShop((shop) => ({
                            ...shop,
                            shopAddressDetail: value,
                          }));
                        }}
                      />
                    </div>
                  </Td>
                </tr>
              </tbody>
            </Table>
            <Text
              style={{
                marginTop: 10,
              }}>
              관리자 정보
            </Text>
            <Grid
              style={{
                width: "100%",
                marginTop: 5,
                height: 600,
              }}
              data={managerList ? managerList : []}
              scrollable="scrollable"
              cellRender={cellRender}>
              <GridColumn field="account" title="아이디" width={130} />
              <GridColumn field="name" title="이름" width={160} />
              <GridColumn field="phone" title="연락처" width={150} />
              <GridColumn field="managerGroup" title="권한그룹" width={100} />
              <GridColumn field="createdAt" title="생성일" width={160} />
              <GridColumn field="status" title="상태" width={120} />
            </Grid>
          </TabStripTab>
          <TabStripTab title="영업일정 관리">
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ width: "50%", height: "fit-content" }}>
                <Text>기본 영업 시간 설정</Text>
                <Table>
                  <tbody>
                    <tr>
                      <Th>기본 근무 시간</Th>
                      <CenterTd>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <div style={{ marginRight: 10 }}>
                            <Checkbox
                              label={"전체"}
                              name={"ALL"}
                              checked={
                                businessDayCheck.length === 7 ? true : false
                              }
                              onChange={({ target }) => {
                                const { name } = target;
                                let arr = [...businessDayCheck];
                                if (name === "ALL") {
                                  if (arr.length === 7) {
                                    arr = [];
                                  } else {
                                    arr = [
                                      ...week.map((item) => {
                                        return item.value;
                                      }),
                                    ];
                                  }
                                  setBusinessDayCheck(arr);
                                }
                              }}
                            />
                          </div>
                          {week.map((item, index) => {
                            const { value, label } = item;
                            return (
                              <div style={{ marginRight: 10 }}>
                                <Checkbox
                                  key={index.toString()}
                                  label={label}
                                  checked={businessDayCheck.includes(value)}
                                  onChange={({ target }) => {
                                    const { name } = target;
                                    let arr = [...businessDayCheck];
                                    if (name === "ALL") {
                                      if (arr.length === 8) {
                                        arr = [];
                                      } else {
                                        arr = [
                                          ...week.map((item) => {
                                            return item.value;
                                          }),
                                        ];
                                      }
                                      setBusinessDayCheck(arr);
                                    } else {
                                      if (arr.includes(value)) {
                                        arr = arr.filter(
                                          (item) => item !== value
                                        );
                                      } else {
                                        arr.push(value);
                                      }
                                      setBusinessDayCheck(arr);
                                    }
                                  }}
                                />
                              </div>
                            );
                          })}
                          <DropDownList
                            style={{
                              width: 80,
                            }}
                            data={hours}
                            textField="name"
                            dataItemKey="value"
                            name="defaultHoursFrom"
                            value={defaultHoursFrom}
                            onChange={handleChange}
                          />{" "}
                          ~{" "}
                          <DropDownList
                            style={{
                              width: 80,
                            }}
                            data={hours}
                            textField="name"
                            dataItemKey="value"
                            name="defaultHoursTo"
                            value={defaultHoursTo}
                            onChange={handleChange}
                          />
                        </div>
                      </CenterTd>
                      <CenterTd>
                        <Button onClick={handleClickBusinessHoursSave}>
                          저장
                        </Button>
                      </CenterTd>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div style={{ width: "50%" }}>
                <Text>휴무일 지정</Text>
                <Table style={{ height: "fit-content" }}>
                  <tbody>
                    <tr>
                      <Th>휴업일시</Th>
                      <CenterTd>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}>
                          <DropDownList
                            style={{
                              width: 80,
                            }}
                            data={holidayRepetitionData}
                            textField="name"
                            dataItemKey="value"
                            name="holidaysRepetition"
                            value={holidaysRepetition}
                            onChange={handleChange}
                          />
                          {holidaysRepetition.value === "once" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}>
                              <div style={{ margin: "0px 10px" }}>시작</div>
                              <div style={{ marginRight: 20 }}>
                                <DatePicker
                                  format={"yyyy-MM-dd"}
                                  value={onceHolidayFrom}
                                  onChange={(e) => {
                                    setOnceHolidayFrom(e.target.value);
                                  }}
                                  min={new Date()}
                                  size="large"
                                  width={120}
                                />
                              </div>
                              <div style={{ margin: "0px 10px" }}>종료</div>
                              <div style={{ marginRight: 20 }}>
                                <DatePicker
                                  format={"yyyy-MM-dd"}
                                  value={onceHolidayTo}
                                  onChange={(e) => {
                                    setOnceHolidayTo(e.target.value);
                                  }}
                                  min={new Date()}
                                  size="large"
                                  width={120}
                                />
                              </div>
                            </div>
                          ) : (
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  margin: "0px 15px 0px 10px",
                                }}>
                                <div style={{ marginRight: 10 }}>시작</div>
                                <DropDownList
                                  style={{
                                    width: 80,
                                    marginRight: 5,
                                  }}
                                  data={month}
                                  defaultItem={defaultMonthItem}
                                  textField="label"
                                  dataItemKey="value"
                                  value={holidayStartMonth}
                                  name="holidayStartMonth"
                                  onChange={handleChange}
                                />{" "}
                                <DropDownList
                                  style={{
                                    width: 80,
                                  }}
                                  data={days}
                                  defaultItem={defaultDayItem}
                                  textField="label"
                                  dataItemKey="value"
                                  name="holidayStartDay"
                                  value={holidayStartDay}
                                  onChange={handleChange}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}>
                                <div style={{ marginRight: 10 }}>종료</div>
                                <DropDownList
                                  style={{
                                    width: 80,
                                    marginRight: 5,
                                  }}
                                  data={month}
                                  defaultItem={defaultMonthItem}
                                  textField="label"
                                  dataItemKey="value"
                                  name="holidayEndMonth"
                                  value={holidayEndMonth}
                                  onChange={handleChange}
                                />{" "}
                                <DropDownList
                                  style={{
                                    width: 80,
                                  }}
                                  data={days}
                                  defaultItem={defaultDayItem}
                                  textField="label"
                                  dataItemKey="value"
                                  name="holidayEndDay"
                                  value={holidayEndDay}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          )}
                          <div>
                            <Checkbox
                              label={"휴무일 취소"}
                              onChange={() => {
                                setCancelHoliday(!cancelHoliday);
                              }}
                            />
                          </div>
                        </div>
                      </CenterTd>
                      <CenterTd>
                        <Button
                          onClick={() => {
                            getVisitorList();
                          }}>
                          저장
                        </Button>
                        {show && cancelHoliday === false && (
                          <Dialog
                            style={{ zIndex: 200 }}
                            title={`${
                              holidaysRepetition.value === "once"
                                ? formatTime(onceHolidayFrom)
                                : `매년 ${holidayStartMonth.value}-${holidayStartDay.value}`
                            } ~ ${
                              holidaysRepetition.value === "once"
                                ? formatTime(onceHolidayTo)
                                : `${holidayEndMonth.value}-${holidayEndDay.value}`
                            } 방문고객 리스트`}
                            onClose={handleSaveHoliday}>
                            <div style={{ width: 1200 }}>
                              {appraisalVisitorList.length > 0 && (
                                <>
                                  <Text>감정평가 방문고객</Text>
                                  <Grid
                                    style={{ marginBottom: 10, maxHeight: 150 }}
                                    scrollable
                                    data={appraisalVisitorList || []}
                                    cellRender={popupCellRender}>
                                    <GridColumn
                                      field="visitDate"
                                      title="방문날짜"
                                      width={130}
                                    />
                                    <GridColumn
                                      field="number"
                                      title="제품번호"
                                      width={130}
                                    />
                                    <GridColumn
                                      field="userName"
                                      title="사용자명"
                                      width={130}
                                    />
                                    <GridColumn
                                      field="phone"
                                      title="핸드폰번호"
                                      width={130}
                                    />
                                  </Grid>
                                </>
                              )}
                              {goodsTransVisitorList.length > 0 && (
                                <>
                                  <Text>실물인출 방문고객</Text>
                                  <Grid
                                    style={{ marginBottom: 10, maxHeight: 150 }}
                                    scrollable
                                    data={goodsTransVisitorList || []}
                                    cellRender={popupCellRender}>
                                    <GridColumn
                                      field="visitDate"
                                      title="방문날짜"
                                      width={130}
                                    />
                                    <GridColumn
                                      field="no"
                                      title="제품번호"
                                      width={130}
                                    />
                                    <GridColumn
                                      field="goldOrSilver"
                                      title="자산타입"
                                      width={130}
                                    />
                                    <GridColumn
                                      field="goodsName"
                                      title="제품명"
                                      width={130}
                                    />
                                    <GridColumn
                                      field="qty"
                                      title="수량"
                                      width={130}
                                    />
                                    <GridColumn
                                      field="userName"
                                      title="사용자명"
                                      width={130}
                                    />
                                    <GridColumn
                                      field="phone"
                                      title="핸드폰번호"
                                      width={130}
                                    />
                                  </Grid>
                                </>
                              )}
                              {safeTradeVisitorList.length > 0 && (
                                <>
                                  <Text>안심직거래 방문고객</Text>
                                  <Grid
                                    style={{ maxHeight: 150 }}
                                    scrollable
                                    data={safeTradeVisitorList || []}
                                    cellRender={popupCellRender}>
                                    <GridColumn
                                      field="visitDate"
                                      title="방문날짜"
                                      width={130}
                                    />
                                    <GridColumn
                                      field="userName"
                                      title="사용자명"
                                      width={130}
                                    />
                                    <GridColumn
                                      field="phone"
                                      title="핸드폰번호"
                                      width={130}
                                    />
                                  </Grid>
                                </>
                              )}
                            </div>
                            <DialogActionsBar>
                              <button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                onClick={handleSaveHoliday}>
                                취소
                              </button>
                              <button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                onClick={() => {
                                  dispatch(
                                    showAlert({
                                      message: `${
                                        holidaysRepetition.value === "once"
                                          ? formatTime(onceHolidayFrom)
                                          : `매년 ${holidayStartMonth.value}-${holidayStartDay.value}`
                                      } ~ ${
                                        holidaysRepetition.value === "once"
                                          ? formatTime(onceHolidayTo)
                                          : `${holidayEndMonth.value}-${holidayEndDay.value}`
                                      } 
                                      휴무일을 ${
                                        cancelHoliday === true ? "취소" : "저장"
                                      }하시겠습니까?`,
                                      cancel: true,
                                      onClick: () => {
                                        handleClickHolidaysSave("holiday");
                                      },
                                    })
                                  );
                                }}>
                                확인
                              </button>
                            </DialogActionsBar>
                          </Dialog>
                        )}
                      </CenterTd>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            {!!calendarData.length && (
              <Scheduler
                timezone={"Etc/UTC"}
                data={calendarData}
                date={date}
                height={670}
                item={CustomItem}
                viewItem={CustomViewItem}
                footer={() => {
                  <React.Fragment />;
                }}
                editable={{
                  select: true,
                }}
                viewSlot={CustomViewSlot}
                onDateChange={handleDateChange}>
                <MonthView />
              </Scheduler>
            )}
            <Text
              style={{
                marginTop: 5,
              }}>
              영업시간 조정
            </Text>
            <Table>
              <tbody>
                <tr>
                  <Th>영업일시</Th>
                  <CenterTd>
                    <div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ alignSelf: "center", marginRight: 10 }}>
                          시작
                        </div>
                        <div style={{ marginRight: 20 }}>
                          <DatePicker
                            format={"yyyy-MM-dd"}
                            value={businessDayFrom}
                            onChange={(e) => {
                              setBusinessDayFrom(e.target.value);
                            }}
                            min={new Date()}
                            size="large"
                            width={120}
                          />
                        </div>
                        <div style={{ alignSelf: "center", marginRight: 10 }}>
                          종료
                        </div>
                        <div style={{ marginRight: 20 }}>
                          <DatePicker
                            format={"yyyy-MM-dd"}
                            value={businessDayTo}
                            onChange={(e) => {
                              setBusinessDayTo(e.target.value);
                            }}
                            min={new Date()}
                            size="large"
                            width={120}
                          />
                        </div>
                        <div style={{ alignSelf: "center" }}>
                          <DropDownList
                            style={{
                              width: 80,
                              marginLeft: 10,
                            }}
                            data={hours}
                            defaultItem={defaultTimeItem}
                            textField="name"
                            dataItemKey="value"
                            name="businessStartHour"
                            value={businessStartHour}
                            onChange={handleChange}
                          />{" "}
                          ~{" "}
                          <DropDownList
                            style={{
                              width: 80,
                            }}
                            data={hours}
                            defaultItem={defaultTimeItem}
                            textField="name"
                            dataItemKey="value"
                            name="businessEndHour"
                            value={businessEndHour}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </CenterTd>
                  <CenterTd>
                    <Button onClick={handleClickHolidaysSave}>저장</Button>
                  </CenterTd>
                </tr>
              </tbody>
            </Table>
          </TabStripTab>
        </TabStrip>
      </Wrap>
    </div>
  );
};

export default StoreManagement;

const CustomViewItem = (props) => {
  return (
    <SchedulerViewItem {...props} style={{ ...props.style, height: 25 }} />
  );
};
