import axios from "axios";
import { STORAGE_KEY } from "../libs/consts";
import { showAlert } from "../store/alertReducer";
import { signOut } from "../store/authReducer";
import store from "../store";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.baseURL = process.env.REACT_APP_ADMIN_API_URL;

const commonInstance = axios.create({ headers: { Authorization: "" } });

// 응답 인터셉터 추가
axios.interceptors.response.use(
  function (response) {
    // 응답 데이터를 가공
    // ...
    return response;
  },
  function (error) {
    // 오류 응답을 처리
    // ...
    // const { store } = require("../store");
    // const token = localStorage.getItem(STORAGE_KEY.accessToken);
    const status = error?.response?.status;
    // console.log(token, "token 가지고 있는지 확인");
    if (status === 401 || typeof error.response === "undefined") {
      // localStorage.removeItem("passwordVerification");
      localStorage.removeItem(STORAGE_KEY.accessToken);
      store.dispatch(
        showAlert({
          message: "인증 토큰이 만료되었습니다.\n다시 로그인해주세요.",
          onClick: () => {
            store.dispatch(signOut());
          },
        })
      );
      return Promise.reject(error);
    } else if (status === 403) {
      store.dispatch(showAlert({ message: error.response.data.message }));
    } else if (status === 422) {
      store.dispatch(showAlert({ message: error.response.data.message }));
    } else {
      store.dispatch(showAlert({ message: "알 수 없는 오류가 발생했습니다." }));
    }
    return Promise.reject(error);
  }
);

//로그인
export const postSignIn = (param) => {
  return commonInstance.post("/api/v1/shop/auth/login", param);
};

//비밀번호/OTP 리셋 요청
export const postResetRequest = (param) => {
  return commonInstance.post("/api/v1/shop/auth/login/reset", param);
};

//비밀번호/OTP 리셋 확인
export const putResetConfirm = (param) => {
  return commonInstance.put("/api/v1/shop/auth/login/reset", param);
};

// 계정 유효성 검사
export const postLoginCheck = (param) => {
  return commonInstance.post("/api/v1/shop/auth/login/check", param);
};

// OTP 생성
export const postCreateOTP = (param) => {
  return commonInstance.post("/api/v1/shop/auth/otp/qrcode", param);
};

// OTP 로그인
export const postLoginOtp = (param) => {
  return commonInstance.post("/api/v1/shop/auth/login/otp", param);
};

//비밀번호 변경
export const putModifyConfirmPassword = (data) => {
  return axios.put(`/api/v1/shop/auth/password`, data);
};

/*회원관리*/

//회원관리 리스트 조회
export const searchMemberList = (param) => {
  return axios.get(`/api/v1/shop/user-management/search${param}`);
};

//회원 상세 조회
export const searchMemberDetail = (id) => {
  return axios.get(`/api/v1/shop/user-management/${id}`);
};

//회원 상세 자산조회
export const searchMemberDetailAsset = (id) => {
  return axios.get(`/api/v1/shop/user-management/${id}/asset`);
};

/*감정평가*/

//감정평가 > 감정현황조회
export const getAppraisal = (query) => {
  return axios.get(`/api/v1/shop/appraisal${query}`);
};

// 감정평가 엑셀 다운로드
export const getAppraisalDownload = (data) => {
  return axios.get(`/api/v1/shop/appraisal/excel${data}`, {
    responseType: "blob",
  });
};

//감정평가 순도별 중량 차감율 조회
export const getAppraisalWeightRates = (query = "") => {
  const token = localStorage.getItem(STORAGE_KEY.accessToken);
  return axios.get(`/api/v1/shop/appraisal/weight-rate${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// 감정평가 > 감정현황 상세 조회
export const getAppraisalRequestDetail = (id) => {
  return axios.get(`/api/v1/shop/appraisal/${id}`);
};

// 감정평가 > 감정현황 > 중량합계 조회
export const getAppraisalTotalWeight = (query) => {
  return axios.get(`/api/v1/shop/appraisal/summary${query}`);
};

// 감정평가 > 입고 마감 예정 팝업
export const getAppraisalCheckDelay = () => {
  return axios.get(`/api/v1/shop/appraisal/check_delay`);
};

// 감정평가 > 미 입고금표시
export const getAppraisalNotStock = () => {
  return axios.get(`/api/v1/shop/appraisal/not_stock`);
};

// 감정평가 > 감정현황 > 상세 > 상태 변경
export const putAppraisalRequestStatus = (id, param) => {
  return axios.put(`/api/v1/shop/appraisal/${id}/status`, param);
};

// 감정평가 > 감정현황 > 상세 > 인쇄 입고 배송
export const patchAppraisalPrintStockDelivery = (id) => {
  return axios.patch(`/api/v1/shop/appraisal/${id}/stock`);
};

/*지체상금*/
// 지체상금 목록
export const getAppraisalPenalty = (param) => {
  return axios.get(`/api/v1/shop/appraisal/penalty${param}`);
};

// 지체상금 내역
export const getAppraisalPenaltyDetail = (param) => {
  return axios.get(`/api/v1/shop/appraisal/summary_info${param}`);
};

/*매수매도*/

//BUY SELL 현재가격
export const getcurrentmarketprice = (types) => {
  const token = localStorage.getItem(STORAGE_KEY.accessToken);
  return axios.get(
    `/api/v1/shop/buy-sell/current-market-price?assetType=${types}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

// 매수 매도 체결 조회
export const getSignedTradeList = (querys) => {
  return axios.get(`/api/v1/shop/buy-sell/signed-list${querys}`);
};

// 매수 매도 미체결 조회
export const getNotSignedTradeList = (querys) => {
  return axios.get(`/api/v1/shop/buy-sell/not-signed-list${querys}`);
};

// 매수 매도 체결 합계
export const getSignedTradeSummaryList = (querys) => {
  return axios.get(`/api/v1/shop/buy-sell/signed-list/summary${querys}`);
};

// 매수 매도 미체결 합계
export const getNotSignedTradeSummaryList = (querys) => {
  return axios.get(`/api/v1/shop/buy-sell/not-signed-list/summary${querys}`);
};

/*안심직거래*/

// 안심직거래 > 접수현황 summary 조회
export const getSafeTradeSummary = () => {
  const token = localStorage.getItem(STORAGE_KEY.accessToken);
  return axios.get(`/api/v1/shop/safe-trade/summary`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// 안심직거래 > 상품 목록 조회
export const getSafeTradeProductList = (querys) => {
  return axios.get(`/api/v1/shop/safe-trade/search${querys}`);
};

// 안심직거래 > Filters 카테고리/ 상태 리스트 조회
export const getSafeTradeFitersList = () => {
  return axios.get(`/api/v1/shop/safe-trade/list`);
};

// 안심직거래 > 상세 > 상품 상태 업데이트 변경
export const putChangeStatus = (param, data) => {
  return axios.put(`/api/v1/shop/safe-trade/item/${param}/status`, data);
};

// 안심직거래 > 상세 > 상품 상태 리스트 조회
export const getSafeTradeProductStatusList = (param) => {
  return axios.get(`/api/v1/shop/safe-trade/item/${param}/status`);
};

// 안심직거래 > 상세 > 상품 이력 목록 조회
export const getSafeTradeProductHistoryList = (param) => {
  return axios.get(`/api/v1/shop/safe-trade/item/${param}/status/history`);
};

// 안심직거래 > 상세 > 제품 접수 정보 조회
export const getSafeTradeReceptionInfo = (param) => {
  return axios.get(`/api/v1/shop/safe-trade/item/${param}/order`);
};

// 안심직거래 > 상세 > 제품 감정 정보 조회
export const getSafeTradeAppraisalInfo = (param) => {
  return axios.get(`/api/v1/shop/safe-trade/item/${param}/appraisal`);
};

// 안심직거래 > 상세 > 제품 등록 정보 조회
export const getSafeTradeRegisterInfo = (param) => {
  return axios.get(`/api/v1/shop/safe-trade/item/${param}/registration`);
};

// 안심직거래 > 상세 > 제품 카테고리 상세 조회
export const getSafeTradeCategoryDetail = (itemId, categoryId) => {
  return axios.get(
    `/api/v1/shop/safe-trade/item/${itemId}/category/${categoryId}`
  );
};

// 안심직거래 > 상세 > 관리자 메모 이력 조회
export const getAdminMemoHistory = (param) => {
  return axios.get(`/api/v1/shop/safe-trade/item/${param}/memo`);
};

// 안심직거래 > 상세 > 관리자 메모 등록
export const postRegisterAdminMemo = (param, data) => {
  return axios.post(`/api/v1/shop/safe-trade/item/${param}/memo`, data);
};

/*대리점관리*/
export const saveShop = (data) => {
  return axios.post(`/api/v1/shop/shop-management`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getShopDetail = () => {
  return axios.get(`/api/v1/shop/shop-management`);
};

export const getBankList = () => {
  return axios.get(`/api/v1/shop/shop-management/bank?size=200`);
};

export const getShopManagementDay = (querys) => {
  return axios.get(`/api/v1/shop/shop-management/hours${querys}`);
};

export const postBusinessHourSave = (data) => {
  return axios.post(`/api/v1/shop/shop-management/business-hours`, data);
};

export const postHolidaysSave = (data) => {
  return axios.post(`/api/v1/shop/shop-management/holidays`, data);
};

export const getVisitorList = (param) => {
  return axios.get(`/api/v1/shop/shop-management/visit-user${param}`);
};

/*내정보*/

// 관리자 > 내 정보
export const getAdminInfo = () => {
  return axios.get(`/api/v1/shop/my-page`);
};

// 관리자 > 내 정보 > 내 정보 수정
export const putEditMyInfo = (data) => {
  return axios.put(`/api/v1/shop/my-page`, data);
};

// 관리자 > 내 정보 > 새 비밀번호 변경
export const putModifyMyPassword = (data) => {
  return axios.put(`/api/v1/shop/my-page/password`, data);
};

/*정산관리 */

// 정산관리 > 가맹점수수료 > 조회
export const getShopFeeList = (query) => {
  return axios.get(`/api/v1/shop/settlement-management${query}`);
};

// 정산관리 > 가맹점수수료 > 합계
export const getShopFeeSummary = (query) => {
  return axios.get(`/api/v1/shop/settlement-management/summary${query}`);
};

/*실물인출 */
// 실물인출 > 카테고리 조회
export const getGoodsCategoryList = () => {
  return axios.get(`/api/v1/shop/goods-withdrawal/goods-category`);
};

// 실물인출 > 현황 조회
export const getOrderSummary = () => {
  return axios.get(`/api/v1/shop/goods-withdrawal/summary`);
};

// 실물인출 > 중량 합계 조회
export const getGoodsWithdrawalTotalWeight = (querys) => {
  return axios.get(`/api/v1/shop/goods-withdrawal/search/summary${querys}`);
};

// 실물인출 > 주문내역 조회
export const getOrderList = (querys) => {
  return axios.get(`/api/v1/shop/goods-withdrawal/search${querys}`);
};

// 실물인출 > 주문 상세 조회
export const getOrderDetail = (transId) => {
  return axios.get(`/api/v1/shop/goods-withdrawal/${transId}`);
};

// 실물인출 > 상품 상세 조회
export const getProductDetail = (goodsId) => {
  return axios.get(`/api/v1/shop/goods-withdrawal/goods/${goodsId}`);
};

// 실물인출 > 상품 주문
export const getProductOrderHistory = (transId) => {
  return axios.get(`/api/v1/shop/goods-withdrawal/order/${transId}`);
};

// 실물인출 > 제작 주문 상태 변경
export const postProductOrderMod = (param) => {
  return axios.post(`/api/v1/shop/goods-withdrawal/order`, param);
};

export const getTradeConfig = (query) => {
  return axios.get(`/api/v1/shop/config${query}`);
};

// 통합거래내역 조회
export const getTransactionHistory = (query) => {
  return axios.get(`/api/v1/shop/trade/user_log${query}`);
};

// 통합거래내역 엑셀 다운로드
export const getIntegrationHistoryDownload = (data) => {
  return axios.get(`/api/v1/shop/trade/user_log/down${data}`, {
    responseType: "blob",
  });
};

/**
 *
 *  BIZ API
 */

/*감정평가 */

// 한돈 시세조회
export const getPurchasePrice = () => {
  return axios.get(`/api/v1/biz/purchase/price`);
};

// 감정평가 리스트 조회
export const getAppraisalBiz = (param) => {
  return axios.get(`/api/v1/biz/purchase${param}`);
};

// 접수 시 전화번호 조회
export const getFindPhone = (param) => {
  return axios.get(`/api/v1/biz/user/find${param}`);
  // return axios.get(`/api/v1/biz/find${param}`);
};

// 문자 인증번호 발송
export const postRequestVerify = (data) => {
  return axios.post(`/api/v1/biz/user/request_verify`, data);
  // return axios.post(`/api/v1/biz/request_verify`, data);
};

// 문자 인증번호 확인
export const postConfirmVerify = (data) => {
  return axios.post(`/api/v1/biz/user/confirm_verify`, data);
};
// 본인인증 requestId 요청(신규 사용자)
export const postPrepareVerification = (data) => {
  return axios.post(`/api/v1/biz/verification/self/prepare`, data);
};

// 본인인증 요청(신규 사용자)
export const getStartVerification = (param) => {
  return axios.get(`/api/v1/biz/verification/self/start${param}`);
};

// 알림톡 요청
export const postNotificationsTalk = (data) => {
  return axios.post(`/api/v1/biz/notifications/talk/userinfo`, data);
};

// 약관동의 확인체크
export const postCheckTerms = (data) => {
  return axios.post(`/api/v1/biz/user/check_terms`, data);
};

// 이용약관 리스트 조회
export const getServiceTerms = () => {
  return axios.get(`/api/v1/biz/service/terms`);
};

// 본인인증 완료(신규 사용자)
export const postCompleteVerification = (data) => {
  return axios.post(`/api/v1/biz/verification/self/complete`, data);
};

// 계좌변경 은행 리스트 조회
export const getBizBankCodeList = () => {
  return axios.get(`/api/v1/biz/bank/code`);
};

// 계좌변경 은행 리스트 조회
export const postBizBankRegister = (data) => {
  return axios.post(`/api/v1/biz/bank/register`, data);
};

// 계좌변경 후 사용자 계좌 조회
export const getBizBankSearch = (param) => {
  return axios.get(`/api/v1/biz/bank/search${param}`);
};

// 순도 관련 제품 목록
export const getPurchaseProductList = () => {
  return axios.get(`/api/v1/biz/purchase/product`);
};

// 접수 전 시세조회
export const postPurchasePrice = (data) => {
  return axios.post(`/api/v1/biz/purchase/price`, data);
};

// 감정평가 접수
export const postPurchase = (data) => {
  return axios.post(`/api/v1/biz/purchase`, data);
};

// 위탁매입 약관 목록 조회
export const getConsignmentTerms = () => {
  return axios.get(`/api/v1/biz/consignment/terms`);
};

// 감정평가 상세 조회
export const getPurchaseDetail = (id, param) => {
  return axios.get(`/api/v1/biz/purchase/${id}${param}`);
};

// 알림톡 상세 조회
export const getConsignmentDetail = (id, param) => {
  return axios.get(`/api/v1/biz/purchase/consignment/${id}${param}`);
};

// 알림톡 - 위탁매입 약관동의 제출
export const postConsignmentSign = (data) => {
  return axios.post(
    `/api/v1/biz/purchase/consignment/sign
  `,
    data
  );
};

// 다이렉트 주문 취소
export const patchDirectCancel = (data) => {
  return axios.patch(
    `/api/v1/biz/purchase/direct/cancel
  `,
    data
  );
};

// 다이렉트 주문
export const patchDirectOrder = (data) => {
  return axios.patch(
    `/api/v1/biz/purchase/direct/order
  `,
    data
  );
};

// 다이렉트 출금요청
export const postDirectWithdraw = (data) => {
  return axios.post(
    `/api/v1/biz/purchase/direct/withdraw
  `,
    data
  );
};

// 지체상금
export const getBizDirectPenalty = (param) => {
  return axios.get(
    `/api/v1/biz/purchase/direct/penalty${param}
  `
  );
};

// 대리점관리
export const getBizShopDetail = () => {
  return axios.get(
    `/api/v1/biz/biz-management
  `
  );
};

// 은행 목록조회
export const getBizBankList = () => {
  return axios.get(
    `/api/v1/biz/biz-management/bank?size=200
  `
  );
};

// 정산관리
export const getBizShopFeeList = (query) => {
  return axios.get(
    `/api/v1/biz/settlement-management${query}
  `
  );
};

// 정산관리 summary
export const getBizShopFeeSummary = (query) => {
  return axios.get(
    `/api/v1/biz/settlement-management/summary${query}
  `
  );
};

// 내정보
export const getBizMyPage = () => {
  return axios.get(
    `/api/v1/biz/my-page
  `
  );
};

// 내정보 수정
export const putBizEditMyPage = (data) => {
  return axios.put(
    `/api/v1/biz/my-page
  `,
    data
  );
};

// 내정보 비밀번호 수정
export const putBizModifyMyPassword = (data) => {
  return axios.put(
    `/api/v1/biz/my-page/password
  `,
    data
  );
};
