import React, {
  useEffect,
  useState,
  useMemo,
  useLayoutEffect,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AppraisalDetail from "./AppraisalDetail";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import styled from "styled-components";
import Button from "../../components/button/Button";
import DefaultLayout from "../../components/layout/DefaultLayout";
import { RadioGroup, Checkbox } from "@progress/kendo-react-inputs";
import Flex from "../../components/layout/Flex";
import * as APIS from "../../libs/apis";
import { useDispatch, useSelector } from "react-redux";
import { showLoading, hideLoading } from "../../store/loadingReducer";
import { showAlert } from "../../store/alertReducer";
import { getter } from "@progress/kendo-react-common";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import Grid from "../../components/grid/Grid";
import {
  numFormat,
  getPurityLabel,
  formatPhone,
  getNoProcessLabel,
  objToQueryStr,
  formatTime,
  numberWithCommas,
} from "../../libs/utils";
import moment from "moment-timezone";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { saveAs } from "file-saver";
import BizRequestModal from "./BizRequestModal";
import useInterval from "hooks/useInterval";

const FiltersLabel = styled.label`
  margin-left: 10px;
  width: ${(props) => (props.width ? props.width : "60px")};
  font-size: 16px;
  font-family: "NotoSansKR-Bold";
  align-self: center;
`;

const Input = styled.input`
  width: ${(props) => (props.width ? props.width : "250px")};
  height: 35px;
  border-radius: 0.2rem;
  border: 1px solid #ededed;
  padding-left: 10px;
  &:focus {
    outline: 1px solid #4583ee;
    box-shadow: 0px 0px 2px red;
  }
`;

const Wrap = styled.div`
  min-width: 1112px;
  max-width: 1740px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 1050px;

  .k-grid .k-grid-container {
    height: 50px;
  }
`;

const Appraisal = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const authReducer = useSelector((s) => s.auth);
  let { shopId, screenType } = authReducer;
  const isShop = screenType === "SHOP";
  const state = location.state?.appraisal || {};
  const navigate = useNavigate();
  const [detailComponent, setDetailComponent] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [appraisalList, setAppraisalList] = useState([]);
  const [goldWeight, setGoldWeight] = useState(0);
  const [silverWeight, setSilverWeight] = useState(0);
  const [filterExpanded, setFilterExpanded] = useState(true);
  const [selectedState, setSelectedState] = useState({});
  const [notStockPrice, setNotStockPrice] = useState(0);
  const [bizRequestVisible, setBizRequestVisible] = useState(false);
  const [bizPurchasePrice, setBizPurchasePrice] = useState({});
  const methodCheckedList = location.state?.appraisal?.method || [];
  const checkAssetTypeData = [
    { label: "전체", value: "" },
    { label: "금", value: "GOLD" },
    { label: "은", value: "SILVER" },
  ];
  const checkServiceTypeData = [
    { label: "금방금방 다이렉트", value: "BIZ_DIRECT" },
    { label: "금방금방 위탁매입", value: "BIZ_CONSIGNMENT" },
  ];
  const checkIsTake = [
    { label: "전체", value: "" },
    { label: "수령", value: "Y" },
    { label: "미수령", value: "N" },
  ];
  const checkMethodData = [
    { label: "내방", value: "SIMPLE_VISIT" },
    { label: "현장감정", value: "DIRECT_VISIT" },
    { label: "택배", value: "SIMPLE_DELIVERY" },
  ];
  const statusList = [
    { label: "접수", value: "REQUEST" },
    { label: "접수취소", value: "CANCEL" },
    { label: "배송중", value: "DELIVERY" },
    { label: "감정시작(감정중)", value: "VERIFY_START" },
    { label: "감정반려", value: "VERIFY_DENY" },
    { label: "승인대기", value: "CONFIRM_WAIT" },
    { label: "감정완료", value: "VERIFY_COMPLETE" },
    { label: "교환완료", value: "EXCHANGE_COMPLETE" },
    { label: "반송신청", value: "RETURN_REQUEST" },
    { label: "반송대기", value: "RETURN_WAIT" },
    { label: "반송완료", value: "RETURN_COMPLETE" },
  ];
  const sortOrderRadioData = [
    {
      label: "최근 순서로",
      value: "DESC",
    },
    {
      label: "오래된 순서로",
      value: "ASC",
    },
  ];

  const searchTotalWeight = () => {
    dispatch(showLoading());
    APIS.getAppraisalTotalWeight(
      objToQueryStr({
        ...state,
        shopId,
      })
    )
      .then((res) => {
        let goldWeight = 0,
          silverWeight = 0;
        res?.data?.data.forEach((item) => {
          if (item.assetType === "GOLD") {
            goldWeight = item.gram || 0;
          }
          if (item.assetType === "SILVER") {
            silverWeight = item.gram || 0;
          }
        });
        setGoldWeight(goldWeight);
        setSilverWeight(silverWeight);
      })
      .catch((err) => {
        setGoldWeight(0);
        setSilverWeight(0);
        dispatch(showAlert({ message: err.response.data.message }));
      })
      .finally(() => dispatch(hideLoading()));
  };

  const parseData = useMemo(() => {
    return appraisalList?.map((row) => {
      // 요청일
      let requestDate = "-";
      if (row.createdAt) {
        requestDate = moment(row.createdAt).format("YYYY.MM.DD HH:mm");
      }
      // 요청일
      let deadlineStockDt = "-";
      if (row.deadlineStockDt) {
        deadlineStockDt = moment(row.deadlineStockDt).format("YYYY.MM.DD");
      }
      //분류
      let assetType = "-";
      if (row.assetType) {
        assetType = row.assetType;
      }
      // 감정 정보
      let info = "-";
      if (row.appraisalWeightGram) {
        info = `${numFormat(row.appraisalWeightGram)}g`;
      }
      // 고객
      let customer = "-";
      if (row.user) {
        customer = `${row.user.name} ${formatPhone(row.user.phone)}`;
      }
      //고객 번호
      let userId = "-";
      if (row.user) {
        userId = row.user.id;
      }
      // 접수 방법
      let method = row.method;
      if (["현장감정", "내방"].includes(method)) {
        method += "\n" + `(${row.shopName})`;
      }
      // 접수 방법
      let stockStatus = row.stockStatus;
      if (["WAIT", "DELIVERY"].includes(stockStatus)) {
        stockStatus = "미 수령";
      } else {
        stockStatus = "수령";
      }
      // 예약일시
      let reservationDateTime = "-";
      if (row.visitDate) {
        reservationDateTime = row.visitDate
          ? `${row.visitDate} ${row.visitTime ? row.visitTime : ""}`
          : "-";
      }
      // 교환완료일시
      let completedAt = "-";
      if (row.completedAt) {
        completedAt = moment(row.completedAt).format("YYYY.MM.DD HH:mm");
      }
      // 최종처리일시
      let lastProcessingDate = "-";
      if (row.updatedAt) {
        lastProcessingDate = moment(row.updatedAt).format("YYYY.MM.DD HH:mm");
      }

      //체결중량 / 감정중량
      let contractWeight = "-";
      if (row.tradeWeightGram || row.appraisalWeightGram) {
        contractWeight = `${numFormat(row.tradeWeightGram)}g / ${numFormat(
          row.appraisalWeightGram
        )}g`;
      }
      //고객 지급금
      let userTradeKrw = "-";
      if (userTradeKrw) {
        userTradeKrw = numFormat(row.userTradeKrw);
      }

      //대리점 수수료
      let shopTradeFee = "-";
      if (shopTradeFee) {
        shopTradeFee = numFormat(row.shopTradeFee);
      }
      return {
        ...row,
        requestDate,
        info,
        customer,
        assetType,
        userId,
        method,
        reservationDateTime,
        completedAt,
        lastProcessingDate,
        deadlineStockDt,
        stockStatus,
        contractWeight,
        userTradeKrw,
        shopTradeFee,
        // serviceType,
      };
    });
  }, [appraisalList]);

  const handleValueChange = (key, value, more) => {
    navigate(location.pathname, {
      replace: true,
      state: {
        appraisal: { ...location.state.appraisal, [key]: value || "", ...more },
      },
    });
  };

  const initState = () => {
    navigate(location.pathname, {
      replace: true,
      state: {
        ...location.state,
        appraisal: {
          page: 0,
          size: 10,
          startDate: formatTime(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 7
            ),
            "YYYY-MM-DD"
          ),
          endDate: formatTime(),
          sortType: "DESC",
          dateType: "CREATED",
          status: "",
          isTaken: "",
          assetType: "",
          deliveryNo: "",
          method: ["SIMPLE_VISIT", "DIRECT_VISIT", "SIMPLE_DELIVERY"],
          bizServiceType: "BIZ_DIRECT",
        },
      },
    });
  };

  const pageChange = (pageIndex, take) => {
    navigate(location.pathname, {
      replace: true,
      state: {
        appraisal: {
          ...location.state?.appraisal,
          page: pageIndex,
          size: take,
        },
      },
    });
  };
  const handleClickDetail = (props) => {
    if (
      authReducer?.role &&
      typeof authReducer?.role === "string" &&
      authReducer?.role.includes("SHOP")
    ) {
      const newData = appraisalList.map((item) => ({
        ...item,
        selected: item.id === props.dataItem.id,
      }));
      setAppraisalList(newData);

      setDetailComponent(() => {
        return null;
      });

      setDetailComponent(() => {
        return (
          <AppraisalDetail
            id={Object.keys(selectedState)}
            fetchList={fetchList}
            serviceType={props.dataItem.serviceType}
            bizPurchasePrice={bizPurchasePrice}
          />
        );
      });
    }
  };

  const clearDetail = () => {
    setDetailComponent(null);
  };

  const cellRender = (cell, props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    if (
      props.field === "no" ||
      props.field === "number" ||
      props.field === "requestDate" ||
      props.field === "assetType" ||
      props.field === "info" ||
      props.field === "customer" ||
      props.field === "userId" ||
      props.field === "status" ||
      props.field === "reservationDateTime" ||
      props.field === "lastProcessingDate" ||
      props.field === "completedAt" ||
      props.field === "deadlineStockDt" ||
      props.field === "stockStatus"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}>
          {props.dataItem[props.field]}
        </td>
      );
    } else if (props.field === "method") {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "start" }}>
          {props.dataItem[props.field]}
        </td>
      );
    } else if (
      props.field === "userTradeKrw" ||
      props.field === "shopTradeFee"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "right" }}>
          {numberWithCommas(props.dataItem[props.field])}
        </td>
      );
    } else if (props.field === "serviceType") {
      return (
        <td>
          <td
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{
              [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
            }}
            {...navigationAttributes}
            style={{ textAlign: "-webkit-center" }}>
            {props.dataItem[props.field]
              .replace("BIZ_CONSIGNMENT", "위탁매입")
              .replace("BIZ_DIRECT", "다이렉트")}
          </td>
        </td>
      );
    }
    return cell;
  };

  const calPercentage = (part, whole) => {
    return (part / whole) * 100;
  };

  const checkNotStockDelay = useCallback(() => {
    if (
      authReducer?.role &&
      typeof authReducer?.role === "string" &&
      authReducer?.role.includes("SHOP")
    ) {
      APIS.getAppraisalNotStock().then(({ data: { data, success } }) => {
        if (success) setNotStockPrice(data);
      });
    }
  }, [authReducer]);

  const fetchList = () => {
    const param = { ...state };
    delete param.skip;
    delete param.take;
    if (!isShop) {
      delete param.method;
    }
    // for (let key in param) {
    //   const value = param[key];

    //   if (typeof value === "string" && !value) {
    //     delete param[key];
    //   }
    // }
    dispatch(showLoading());
    const api = isShop ? APIS.getAppraisal : APIS.getAppraisalBiz;
    api(objToQueryStr(param))
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setAppraisalList(
            data.content.map((item, index) => {
              return { ...item, no: data.content.length - index };
            })
          );
          setTotalCount(data.totalElements);
          searchTotalWeight();
          checkNotStockDelay();
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((e) => {
        showAlert({ message: e.response.data.message });
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  const onClickDownload = () => {
    const param = { ...state };
    delete param.skip;
    delete param.take;

    dispatch(showLoading());
    const queryString = objToQueryStr(param);
    APIS.getAppraisalDownload(queryString)
      .then((res) => {
        const { data } = res;
        const blob = new Blob([data]);
        saveAs(
          blob,
          `감정평가_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
        );
      })
      .catch((err) => {
        console.log(err.data);
        // dispatch(showAlert({ message: err.response.data.message }))
      })
      .finally(() => dispatch(hideLoading()));
  };

  const onClickRequest = () => {
    setBizRequestVisible(!bizRequestVisible);
  };

  useEffect(() => {
    if (Object.keys(state).length === 0) {
      navigate(location.pathname, {
        replace: true,
        state: {
          ...location.state,
          appraisal: {
            page: 0,
            size: 10,
            startDate: formatTime(
              new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - 7
              ),
              "YYYY-MM-DD"
            ),
            endDate: formatTime(),
            sortType: "DESC",
            dateType: "CREATED",
            assetType: "",
            isTaken: "",
            deliveryNo: "",
            method: ["SIMPLE_VISIT", "DIRECT_VISIT", "SIMPLE_DELIVERY"],
            bizServiceType: "BIZ_DIRECT",
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(state).length !== 0) {
      fetchList();
      //미 입고금 조회
    }
  }, [state.page, state.size, state.sortType]);

  useEffect(() => {
    if (Object.keys(selectedState).length !== 0) {
      setDetailComponent(() => {
        return null;
      });

      if (
        authReducer?.role &&
        typeof authReducer?.role === "string" &&
        authReducer?.role.includes("SHOP")
      ) {
        setDetailComponent(() => {
          return (
            <AppraisalDetail
              id={Object.keys(selectedState)}
              fetchList={fetchList}
            />
          );
        });
      }
    }
  }, [selectedState]);

  useMemo(() => {
    if (
      !localStorage.getItem("kumbang.manager.checkDelay") ||
      moment().isAfter(localStorage.getItem("kumbang.manager.checkDelay"))
    ) {
      APIS.getAppraisalCheckDelay().then(({ data: { success, data } }) => {
        if (success) {
          dispatch(
            showAlert({
              message: data,
              onHeaderCancel: () => {
                localStorage.setItem(
                  "kumbang.manager.checkDelay",
                  moment().endOf("day").toISOString()
                );
              },
            })
          );
        }
      });
    }
  }, []);

  useInterval(() => {
    if (!isShop) {
      APIS.getPurchasePrice().then(({ data: { success, data, message } }) => {
        if (success) {
          setBizPurchasePrice(data);
        } else {
          dispatch(showAlert({ message: message }));
        }
      });
    }
  }, 10000);

  return (
    <DefaultLayout detail={detailComponent} clearDetail={clearDetail}>
      {!isShop && (
        <div>
          <Flex>
            <Flex>
              <div style={{ fontWeight: "bold", color: "#005f99" }}>
                금방금방 다이렉트 시장가
              </div>
              <div>
                고객 지급 예상 금액:
                {numberWithCommas(
                  bizPurchasePrice?.expectedDirectMarketCustomer
                )}
                원 / 예상 대리점 수수료 :{" "}
                {numberWithCommas(bizPurchasePrice?.expectedDirectMarketShop)}원
              </div>
            </Flex>
            <Flex>
              <div style={{ fontWeight: "bold", color: "#005f99" }}>
                금방금방 다이렉트 추천가
              </div>
              <div>
                고객 지급 금액 :{" "}
                {numberWithCommas(
                  bizPurchasePrice?.expectedDirectSuggestCustomer
                )}
                원 / 대리점 수수료 :
                {numberWithCommas(bizPurchasePrice?.expectedDirectSuggestShop)}
                원
              </div>
            </Flex>
          </Flex>
          <Flex>
            <div>
              위탁매입 시세{" "}
              {numberWithCommas(bizPurchasePrice?.expectedConsignmentPrice)}원 /
              대리점 수수료 :{" "}
              {numberWithCommas(bizPurchasePrice?.expectedConsignmentShop)}원
            </div>
          </Flex>
        </div>
      )}
      <Wrap>
        <Flex
          style={{
            width: "100%",
            display: "flex",
          }}>
          <PanelBar
            onSelect={() => {
              setFilterExpanded(!filterExpanded);
            }}
            style={{ width: "100%", border: "1px solid #9e9e9e" }}>
            <PanelBarItem expanded={filterExpanded} title={"검색 조건"}>
              <div style={{ padding: "0.5%" }}>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                  }}>
                  <Flex
                    style={{
                      fontSize: 15,
                      fontFamily: "NotoSansKR-Bold",
                      flexDirection: "row",
                      display: "flex",
                      lineHeight: 2,
                      width: "50%",
                      alignItems: "center",
                    }}>
                    <select
                      style={{ height: 35 }}
                      value={state?.dateType ? state?.dateType : ""}
                      onChange={(e) => {
                        handleValueChange("dateType", e.target.value);
                      }}>
                      <option value={"CREATED"}>요청일</option>
                      <option value={"COMPLETED"}>완료일</option>
                    </select>
                    &nbsp; &nbsp;
                    <Input
                      style={{ width: 150 }}
                      type="date"
                      value={state?.startDate ? state.startDate : ""}
                      onChange={(e) => {
                        handleValueChange("startDate", e.target.value);
                      }}
                    />
                    &nbsp;~&nbsp;
                    <Input
                      style={{ width: 150 }}
                      type="date"
                      value={state?.endDate ? state?.endDate : ""}
                      onChange={(e) => {
                        handleValueChange("endDate", e.target.value);
                      }}
                    />
                  </Flex>
                  <Flex
                    style={{
                      display: "flex",
                      width: "50%",
                    }}>
                    <FiltersLabel style={{ marginRight: 20 }}>
                      회원 정보
                    </FiltersLabel>
                    <Input
                      autoFocus={true}
                      placeholder="이름, 휴대폰"
                      value={state?.user ? state?.user : ""}
                      onChange={(e) => {
                        handleValueChange("user", e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          fetchList();
                        }
                      }}
                    />
                  </Flex>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                  }}>
                  <div
                    style={{
                      display: "flex",
                      width: "50%",
                      alignItems: "center",
                    }}>
                    <FiltersLabel>제품</FiltersLabel>
                    <RadioGroup
                      data={checkAssetTypeData}
                      layout={"horizontal"}
                      value={state?.assetType}
                      onChange={(e) => {
                        handleValueChange("assetType", e.value);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: 15,
                      fontFamily: "NotoSansKR-Bold",
                      flexDirection: "row",
                      display: "flex",
                      lineHeight: 2,
                      width: "50%",
                    }}>
                    <FiltersLabel style={{ marginRight: 20 }}>
                      상태
                    </FiltersLabel>
                    <select
                      style={{
                        width: "250px",
                      }}
                      value={state.status}
                      onChange={(e) => {
                        handleValueChange("status", e.target.value);
                      }}>
                      <option value="">{"전체"}</option>
                      {statusList.map((item) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                  }}>
                  {isShop ? (
                    <div
                      style={{
                        display: "flex",
                        width: "50%",
                        alignItems: "center",
                      }}>
                      <FiltersLabel>접수</FiltersLabel>
                      {checkMethodData.map((item, index) => {
                        const { value: checkValue, label } = item;
                        return (
                          <div
                            style={{
                              marginRight: 20,
                              fontSize: 16,
                              fontFamily: "NotoSansKR-Bold",
                            }}>
                            <Checkbox
                              key={index.toString()}
                              checked={methodCheckedList.includes(checkValue)}
                              onChange={(e) => {
                                if (e.target.value) {
                                  handleValueChange("method", [
                                    ...methodCheckedList,
                                    checkValue,
                                  ]);
                                } else {
                                  handleValueChange(
                                    "method",
                                    methodCheckedList.filter(
                                      (e) => e !== checkValue
                                    )
                                  );
                                }
                              }}
                              label={label}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        width: "50%",
                        alignItems: "center",
                      }}>
                      <FiltersLabel>구분</FiltersLabel>
                      <RadioGroup
                        data={checkServiceTypeData}
                        layout={"horizontal"}
                        value={state?.bizServiceType}
                        onChange={(e) => {
                          handleValueChange("bizServiceType", e.value);
                        }}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      width: "50%",
                      alignItems: "center",
                    }}>
                    <FiltersLabel width={"100px"}>본사 수령여부</FiltersLabel>
                    <RadioGroup
                      data={checkIsTake}
                      layout={"horizontal"}
                      value={state?.isTaken}
                      onChange={(e) => {
                        handleValueChange("isTaken", e.value);
                      }}
                    />
                  </div>
                </div>
                {isShop && (
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      marginTop: 10,
                    }}>
                    <FiltersLabel>송장번호</FiltersLabel>
                    <Input
                      autoFocus={true}
                      placeholder="송장번호"
                      value={state?.deliveryNo ? state?.deliveryNo : ""}
                      onChange={(e) => {
                        handleValueChange("deliveryNo", e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          fetchList();
                        }
                      }}
                    />
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: 10,
                  }}>
                  <Button
                    className="buttons-container-button"
                    icon="search"
                    style={{ marginRight: 5 }}
                    onClick={fetchList}>
                    검색
                  </Button>
                  <Button
                    className="buttons-container-button"
                    icon="refresh"
                    style={{ marginRight: 5 }}
                    onClick={initState}>
                    초기화
                  </Button>
                  {isShop ? (
                    <Button
                      icon="excel"
                      className="buttons-container-button"
                      onClick={onClickDownload}>
                      택배 접수 Excel
                    </Button>
                  ) : (
                    <Button
                      className="buttons-container-button"
                      onClick={onClickRequest}>
                      접수
                    </Button>
                  )}
                </div>
              </div>
            </PanelBarItem>
          </PanelBar>
        </Flex>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: 16,
            fontFamily: "NotoSansKR-Bold",
          }}>
          <div
            style={{
              display: "flex",
              fontSize: 16,
              fontFamily: "NotoSansKR-Bold",
            }}>
            <div
              style={{ display: "flex", marginRight: 10, fontWeight: "bold" }}>
              금 중량 합계 : {numFormat(goldWeight) || 0} g
            </div>
            <div
              style={{ display: "flex", marginRight: 10, fontWeight: "bold" }}>
              은 중량 합계 : {numFormat(silverWeight) || 0} g
            </div>
            {authReducer?.role &&
              typeof authReducer?.role === "string" &&
              authReducer?.role.includes("SHOP") && (
                <div
                  style={{
                    display: "flex",
                    fontWeight: "bold",
                    marginRight: 10,
                  }}>
                  미 입고금액 :{" "}
                  <span
                    style={{
                      color:
                        calPercentage(
                          notStockPrice?.notStockKrw,
                          notStockPrice?.allowKrw
                        ) >= 100
                          ? "red"
                          : calPercentage(
                              notStockPrice?.notStockKrw,
                              notStockPrice?.allowKrw
                            ) >= 80
                          ? "blue"
                          : "black",
                    }}>
                    {numFormat(notStockPrice?.notStockKrw) || 0}
                  </span>
                  원 {" / "}
                  거래 허용금 : {numFormat(notStockPrice?.allowKrw || 0)} 원
                </div>
              )}
            {!isShop && (
              <div style={{ display: "flex", fontWeight: "bold" }}>
                / 위탁매입 대리점 수수료 :{" "}
                <span
                  style={{
                    color:
                      calPercentage(
                        notStockPrice?.notStockKrw,
                        notStockPrice?.allowKrw
                      ) >= 100
                        ? "red"
                        : calPercentage(
                            notStockPrice?.notStockKrw,
                            notStockPrice?.allowKrw
                          ) >= 80
                        ? "blue"
                        : "black",
                  }}>
                  {numFormat(notStockPrice?.notStockKrw) || 0}
                </span>
                원 {" / "}
                다이렉트 대리점 수수료 :{" "}
                {numFormat(notStockPrice?.allowKrw || 0)} 원
              </div>
            )}
          </div>
          <RadioGroup
            data={sortOrderRadioData}
            layout={"horizontal"}
            value={state?.sortType}
            onChange={(e) => {
              handleValueChange("sortType", e.value);
            }}
          />
        </div>
        <Grid
          data={parseData}
          cellRender={cellRender}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          filterExpanded={filterExpanded}
          expandedWidth={799}
          unExpandedWidth={972}
          total={totalCount}
          pageable={{
            pageSizes: [10, 30, 50, 100],
          }}
          onPageChange={pageChange}
          onRowClick={handleClickDetail}
          columnData={
            isShop
              ? [
                  { field: "no", title: "번호", width: 100 },
                  { field: "number", title: "접수번호", width: 203 },
                  { field: "requestDate", title: "요청일", width: 170 },
                  {
                    field: "deadlineStockDt",
                    title: "입고 마감일",
                    width: 170,
                  },
                  { field: "assetType", title: "분류", width: 100 },
                  { field: "info", title: "감정정보", width: 120 },
                  { field: "customer", title: "고객", width: 150 },
                  { field: "userId", title: "고객번호", width: 100 },
                  { field: "method", title: "접수 방법", width: 200 },
                  { field: "status", title: "진행상태", width: 120 },
                  { field: "stockStatus", title: "본사 수령여부", width: 150 },
                  {
                    field: "reservationDateTime",
                    title: "예약일시",
                    width: 170,
                  },
                  { field: "completedAt", title: "완료일시", width: 170 },
                  {
                    field: "lastProcessingDate",
                    title: "최종 처리일시",
                    width: 170,
                  },
                ]
              : [
                  { field: "no", title: "번호", width: 100 },
                  { field: "serviceType", title: "구분", width: 100 },
                  {
                    field: "contractWeight",
                    title: "체결중량/감정중량",
                    width: 180,
                  },
                  {
                    field: "userTradeKrw",
                    title: "고객 지급금",
                    width: 150,
                  },
                  { field: "shopTradeFee", title: "대리점 수수료", width: 150 },
                  { field: "deadlineStockDt", title: "입고마감일", width: 130 },
                  { field: "assetType", title: "분류", width: 100 },
                  { field: "customer", title: "고객", width: 150 },
                  { field: "userId", title: "고객번호", width: 100 },
                  { field: "number", title: "접수번호", width: 130 },
                  { field: "status", title: "진행상태", width: 100 },
                  {
                    field: "stockStatus",
                    title: "본사 수령여부",
                    width: 130,
                  },
                  { field: "completedAt", title: "완료일시", width: 170 },
                  {
                    field: "lastProcessingDate",
                    title: "최종 처리일시",
                    width: 170,
                  },
                ]
          }
        />
      </Wrap>
      <BizRequestModal
        bizRequestVisible={bizRequestVisible}
        setBizRequestVisible={setBizRequestVisible}
      />
    </DefaultLayout>
  );
};

export default Appraisal;
