import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import * as APIS from "../../libs/apis";
import { showAlert } from "store/alertReducer";
import IconRight from "../../assets/images/ico_arrow_right.png";
import CheckBoxOn from "../../assets/images/checkBox_on.png";
import CheckBoxOff from "../../assets/images/checkBox_off.png";
import CanvasDraw from "react-canvas-draw";
import Flex from "components/layout/Flex";
import { formatTime, numberWithCommas, objToQueryStr } from "libs/utils";

const AgreeSign = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { uuid } = useParams(); // 제출 시 사용
  const queryString = new URLSearchParams(
    location.search.replace(/&amp;/gi, "&")
  );
  const id = queryString.get("id") || ""; //detail id
  const [terms, setTerms] = useState([]);
  const tempCanvasRef = useRef(null);
  const canvasWrapRef = useRef(null);
  const [canvasSize, setCanvasSize] = useState({ width: 300, height: 200 });
  const [detailData, setDetailData] = useState({});

  useEffect(() => {
    APIS.getConsignmentTerms().then(
      ({ data: { success, data = [], message } }) => {
        if (success) {
          setTerms(
            data.map((item) => {
              const checked = false;
              return { ...item, checked };
            })
          );
        } else {
          dispatch(showAlert({ message: message }));
        }
      }
    );
    const data = { bizServiceType: ["BIZ_CONSIGNMENT"] };
    APIS.getConsignmentDetail(id, objToQueryStr(data)).then(
      ({ data: { success, data, message } }) => {
        if (success) {
          setDetailData(data);
        } else {
          dispatch(showAlert({ message: message }));
        }
      }
    );
    const updateCanvasSize = () => {
      if (canvasWrapRef.current) {
        const { offsetWidth } = canvasWrapRef.current;
        const newWidth = offsetWidth;
        const newHeight = offsetWidth * 0.6;
        setCanvasSize({ width: newWidth, height: newHeight });
      }
    };

    // 초기화 및 리사이즈 이벤트 리스너 등록
    updateCanvasSize();
    window.addEventListener("resize", updateCanvasSize);

    // 정리(clean-up) 작업
    return () => {
      window.removeEventListener("resize", updateCanvasSize);
    };
  }, []);

  const handleClickCheck = (event, i) => {
    const checked = event.target.checked;
    const item = terms[i];
    if (item.checked !== checked) {
      const copy = [...terms];
      copy.splice(i, 1, { ...item, checked });
      setTerms(copy);
    }
  };

  const canNext =
    terms.findIndex(({ checked, required }) => required && !checked) === -1;

  const handleClickSubmit = () => {
    if (canNext) {
      const termsTypes = terms
        .filter(({ checked }) => checked)
        .map(({ type }) => type);
      const data = {
        requestId: id,
        number: uuid,
        termsTypes: termsTypes,
        pointData: tempCanvasRef.current.getSaveData(),
      };

      APIS.postConsignmentSign(data).then(
        ({ data: { success, data, message } }) => {
          if (success) {
            dispatch(
              showAlert({
                message: "제출 완료하였습니다",
                onClick: () => {
                  window.location.href = "kakaotalk://inappbrowser/close";
                },
              })
            );
          } else {
            dispatch(showAlert({ message: `message : ${message}, id: ${id}` }));
          }
        }
      );
    }
  };
  const {
    user,
    createdAt,
    goldProduct,
    silverProduct,
    shop,
    assetType,
    totalGoodsPrice,
  } = detailData;
  const productList = assetType === "GOLD" ? goldProduct : silverProduct;
  return (
    <div>
      <Wrap className="sub_wrap08_2">
        <div
          style={{
            width: "max-content",
            borderRadius: 12,
            border: "1px #D6D6D6 solid",
            justifyContent: "center",
            alignItems: "center",
            borderCollapse: "collapse",
          }}>
          <Table>
            <tr>
              <Th colSpan={2}>일시</Th>
              <Td colSpan={6}>{formatTime(createdAt, "YYYY-MM-DD HH:MM")}</Td>
            </tr>
            <tr>
              <Th colSpan={2}>성명</Th>
              <Td colSpan={6}>
                <Flex style={{ justifyContent: "space-evenly" }}>
                  <div>{`${user?.name}`}</div>
                  <div>(서명)</div>
                </Flex>
              </Td>
            </tr>
            <tr>
              <Th colSpan={2}>전화번호</Th>
              <Td colSpan={6}>{user?.phone}</Td>
            </tr>
            <tr>
              <Th colSpan={2}>예금주</Th>
              <Td colSpan={6}>{user?.name}</Td>
            </tr>
            <tr>
              <Th colSpan={2}>은행</Th>
              <Td colSpan={6}>{user?.bankName}</Td>
            </tr>
            <tr>
              <Th colSpan={2}>계좌번호</Th>
              <Td colSpan={6}>{user?.bankAccount}</Td>
            </tr>
            <tr>
              <Th colSpan={1}>구분</Th>
              <Th colSpan={2}>품목</Th>
              <Th colSpan={2}>단가(원)</Th>
              <Th colSpan={1}>환산중량(g)</Th>
              <Th colSpan={2}>가격(원)</Th>
            </tr>
            {productList?.map((item) => {
              return (
                <tr>
                  <Td colSpan={1}>{item?.purityTypeName}</Td>
                  <Td colSpan={2}>{item?.name}</Td>
                  <Td colSpan={2}>{numberWithCommas(item?.gramUnitPrice)}</Td>
                  <Td colSpan={1}>
                    {numberWithCommas(item?.appraisalWeightGram)}
                  </Td>
                  <Td colSpan={2}>{numberWithCommas(item?.goodsPrice)}</Td>
                </tr>
              );
            })}
            <tr>
              <Td colSpan={3}>※ 위와 같이 판매하였음을 확인합니다</Td>
              <Th colSpan={2}>총액</Th>
              <Th colSpan={3}>{numberWithCommas(totalGoodsPrice)} 원</Th>
            </tr>
          </Table>
          <div style={{ padding: 15 }}>
            <div>
              상기 물품에 대한 판매와 개인정보 이용에 충분히 안내받았으며
              개인정보 수집에 동의합니다.
            </div>
            <div>{formatTime(createdAt, "YYYY년 MM월 DD일")}</div>
            <Flex style={{ justifyContent: "flex-end" }}>
              <Flex style={{ alignItems: "center" }}>
                <Flex style={{ marginRight: 20 }}>
                  <div>성명 :</div>
                  <div>{user?.name || "홍길동"}</div>
                </Flex>
                <div>(서명)</div>
              </Flex>
            </Flex>
            <Flex>
              <div>취급점명 :</div>
              <div>{shop?.name}</div>
            </Flex>
          </div>
        </div>
        <CanvasContainer
          style={{
            position: "sticky",
            marginTop: 14,
          }}
          ref={canvasWrapRef}>
          <OverlayText>서명을 해주세요</OverlayText>
          <CanvasDraw
            ref={tempCanvasRef}
            brushColor="black"
            brushRadius={3}
            lazyRadius={2}
            backgroundColor="#F2F2F2"
            canvasWidth={canvasSize?.width}
            canvasHeight={canvasSize?.height}
            hideGrid={true}
            style={{
              border: "1px #D6D6D6 solid",
              borderRadius: 12,
            }}
          />
        </CanvasContainer>
        {terms?.map(({ title, required, checked, linkUrl }, i) => {
          return (
            <Check className="terms_check" key={i}>
              <input
                type="checkbox"
                id={`check${i}`}
                name="term_check"
                checked={checked}
                onChange={(checked) => handleClickCheck(checked, i)}
                style={{ cursor: "pointer" }}
              />
              <label
                htmlFor={`check${i}`}
                onClick={(event) => {
                  const target = event.target;
                  if (
                    target.tagName !== "INPUT" &&
                    target.tagName !== "LABEL"
                  ) {
                    event.preventDefault(); // 체크박스 상태 변경 방지
                    window.location.href = linkUrl;
                  }
                }}>
                <p>
                  <RequiredText required={required}>
                    {`${required ? "[필수]" : "[선택]"}`}{" "}
                  </RequiredText>
                  {title}
                  <img src={IconRight} />
                </p>
              </label>
            </Check>
          );
        })}
      </Wrap>
      <Button>
        <button
          className={`${canNext ? "on" : "off"}`}
          onClick={() => handleClickSubmit()}>
          제출하기
        </button>
      </Button>
    </div>
  );
};

export default AgreeSign;

const Wrap = styled.div`
background-color: #fff;
height: calc(100vh - 56px);
padding:64px 24px 80px 24px;
box-sizing: border-box;
overflow: scroll;
`;

const Check = styled.div`
  padding-left: 16px;
  display: flex;
  align-items: center;
  height: 48px;

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    display: inline-block;
    width: 100%;
    height: 48px;
    position: relative;
    background: url(${CheckBoxOff}) no-repeat;
    background-size: 24px 24px;
    background-position: left center;
    cursor: pointer;
  }

  input[type="checkbox"] + label img {
    position: absolute;
    right: 0;
    height: 48px;
    top: 0;
  }

  input[type="checkbox"]:checked + label::after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: 12px;
    background: url(${CheckBoxOn}) no-repeat;
    background-size: 100% 100%;
  }

  input[type="checkbox"] + label p {
    margin: 0px 0px 0px 36px;
    font-size: 14px;
    color: #000;
    line-height: 48px;
  }

  input[type="checkbox"]:checked + label h3 {
    color: #00baff;
  }
`;

const RequiredText = styled.span`
color: ${(props) => (props.required ? "#00BAFF" : "")};
`;

const Button = styled.div`
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 24px 40px;
  box-sizing: border-box;
  text-align: center;
  z-index: 10;

  button {
    width: 100%;
    padding: 16px 0;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }

  button.on {
    background-color: #01A3FE;
    color: #fff;
  }

  button.off {
    background-color: #ddd;
    color: #bbb;
  }
`;

const CanvasContainer = styled.div`
  position: relative;
`;

const OverlayText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: bold;
  color: #ccc;
  pointer-events: none; 
  z-index: 2; 
`;

const Table = styled.table`
  width: 100%;
  border: 1px solid #D6D6D6;
  border-collapse: collapse;
`;

const Th = styled.th`
  text-align: center;
  padding: 5px;
  border: 1px solid #D6D6D6;
`;
const Td = styled.td`
  border: 1px solid #D6D6D6;
  text-align: center;
  padding: 8px;
`;
