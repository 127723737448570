import React, { useState, useEffect } from "react";
import Flex from "../../components/layout/Flex";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import Button from "../../components/button/Button";
import DefaultLayout from "../../../src/components/layout/DefaultLayout";
import { Checkbox } from "@progress/kendo-react-inputs";
import {
  IntlProvider,
  loadMessages,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import {
  Grid,
  GridColumn,
  getSelectedState,
  getSelectedStateFromKeyDown,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import * as APIS from "../../libs/apis";
import { getter } from "@progress/kendo-react-common";
import esMessages from "../../libs/es.json";
import { objToQueryStr, formatTime, numberWithCommas } from "../../libs/utils";
import { hideLoading, showLoading } from "../../store/loadingReducer";
import { showAlert } from "../../store/alertReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { createIndex } from "../../libs/utils";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

loadMessages(esMessages, "es-KR");
const DATA_ITEM_KEY = "id";
const idGetter = getter(DATA_ITEM_KEY);

const initialDataState = {
  skip: 0,
  take: 10,
};

const Input = styled.input`
  width: ${(props) => (props.width ? props.width : "250px")};
  height: 35px;
  border-radius: 0.2rem;
  border: 1px solid #ededed;
  padding-left: 10px;
  &:focus {
    outline: 1px solid #4583ee;
    box-shadow: 0px 0px 2px red;
  }
`;

const FiltersLabel = styled.label`
  margin-left: 10px;
  width: 100px;
  align-self: center;
  font-size: 16px;
  font-family: "NotoSansKR-Bold";
  align-self: center;
`;

const Wrap = styled.div`
  min-width: 1112px;
  max-width: 1740px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 1050px;

  .k-grid .k-grid-container {
    height: 50px;
  }
`;

const SelectBox = styled.select`
  height: 35px;
  borderradius: 0.2rem;
  border: 1px solid #ededed;
`;

const SettlementManagement = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = location.state?.adjustment || {};
  const [page, setPage] = useState(initialDataState);
  const [selectedState, setSelectedState] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const auth = useSelector((s) => s.auth);
  const { isKorda, shopId, screenType } = auth;
  const isShop = screenType === "SHOP";
  const [shopFees, setShopFees] = useState([]);
  const [summary, setSummary] = useState({});
  const checkSettleStatusData = [
    { label: "완료", value: "DONE" },
    { label: "미완료", value: "NEW" },
    { label: "취소", value: "CANCEL" },
  ];
  const settleStatusCheckedList =
    location.state?.adjustment?.settleStatus || [];
  const supplyFee = [
    { label: "추천", key: "recommendFee" },
    { label: "실물 인출", key: "outFee" },
    { label: "감정 매도", key: "appraisalFee" },
    { label: "안심 접수", key: "safeTradeInFee" },
    { label: "안심 구매", key: "safeTradeOutFee" },
  ];
  const totalFee = [
    { label: "공급가", key: "supplyFee" },
    { label: "부가세", key: "vat" },
    { label: "합계", key: "totalFee" },
  ];
  const handleValueChange = (key, value, more) => {
    navigate(location.pathname, {
      replace: true,
      state: {
        adjustment: {
          ...location.state?.adjustment,
          [key]: value || "",
          ...more,
        },
      },
    });
  };

  const initState = () => {
    navigate(location.pathname, {
      replace: true,
      state: {
        ...location.state,
        adjustment: {
          page: page.skip,
          size: page.take,
          dateType: "CREATED",
          fromDate: formatTime(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 7
            ),
            "YYYY-MM-DD"
          ),
          toDate: formatTime(),
          settleStatus: ["DONE", "NEW", "CANCEL"],
        },
      },
    });
  };
  const cellRender = (cell, props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    if (
      props.field === "no" ||
      props.field === "fromDate" ||
      props.field === "toDate" ||
      props.field === "bankName" ||
      props.field === "accountNumber" ||
      props.field === "updatedAt" ||
      props.field === "status"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}>
          {props.dataItem[props.field]}
        </td>
      );
    } else if (
      props.field === "tradeKrw" ||
      props.field === "recommendFee" ||
      props.field === "outFee" ||
      props.field === "appraisalFee" ||
      props.field === "safeTradeInFee" ||
      props.field === "safeTradeOutFee" ||
      props.field === "supplyFee" ||
      props.field === "vat" ||
      props.field === "totalFee" ||
      props.field === "penaltyKrw" ||
      props.field === "discountKrw" ||
      props.field === "settleKrw" ||
      props.field === "settleAmount"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "right" }}>
          {numberWithCommas(props.dataItem[props.field])}
        </td>
      );
    }
    return cell;
  };

  const pageChange = (event) => {
    navigate(location.pathname, {
      replace: true,
      state: {
        adjustment: {
          ...location.state?.adjustment,
          page: event.page.skip / event.page.take,
          size: event.page.take,
        },
      },
    });
  };
  const onKeyDown = (event) => {
    const newSelectedState = getSelectedStateFromKeyDown({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
  };

  const fetchList = () => {
    const { fromDate, toDate } = state;
    if (!fromDate || !toDate) {
      dispatch(showAlert({ message: "기간을 설정해주세요." }));
      return;
    }

    const param = { ...state };

    param["fromDate"] =
      new Date(fromDate).getDate() <= 15
        ? moment(fromDate).format("YYYY-MM-01")
        : moment(fromDate).format("YYYY-MM-16");

    param["toDate"] =
      new Date(toDate).getDate() <= 15
        ? moment(toDate).format("YYYY-MM-15")
        : moment(toDate).endOf("month").format("YYYY-MM-DD");

    if (!isKorda) {
      param["shopId"] = shopId;
    }

    dispatch(showLoading());
    const listApi = isShop
      ? APIS.getShopFeeList(objToQueryStr(param))
      : APIS.getBizShopFeeList(objToQueryStr(param));
    listApi
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setShopFees(
            data.map((item) => {
              return {
                ...item,
                tradeKrw: numberWithCommas(item.tradeKrw),
                vat: numberWithCommas(item.vat),
                settleAmount: numberWithCommas(item.settleKrw + item.vat),
                status:
                  item.status
                    ?.replace("NEW", "미완료")
                    ?.replace("DONE", "정산완료")
                    ?.replace("CANCEL", "취소") || "-",
                updatedAt:
                  item.status !== "NEW"
                    ? formatTime(item.updatedAt, "YYYY-MM-DD")
                    : "-",
              };
            })
          );
          setTotalCount(data.length);
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((error) =>
        dispatch(showAlert({ message: error.response.data.message }))
      )
      .finally(() => dispatch(hideLoading()));

    const summaryApi = isShop
      ? APIS.getShopFeeSummary(objToQueryStr(param))
      : APIS.getBizShopFeeSummary(objToQueryStr(param));
    summaryApi
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setSummary(data);
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((error) =>
        dispatch(showAlert({ message: error.response.data.message }))
      )
      .finally(() => dispatch(hideLoading()));
  };

  useEffect(() => {
    if (Object.keys(state).length === 0) {
      navigate(location.pathname, {
        replace: true,
        state: {
          ...location.state,
          adjustment: {
            page: page.skip,
            size: page.take,
            dateType: "CREATED",
            fromDate: formatTime(
              new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - 7
              ),
              "YYYY-MM-DD"
            ),
            toDate: formatTime(),
            settleStatus: ["DONE", "NEW", "CANCEL"],
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(state).length !== 0) {
      fetchList();
    }
  }, [state.page, state.size]);

  const { sumTradeKrw, sumTotal, sumSupply, sumVat } = summary;

  return (
    <DefaultLayout>
      <Wrap>
        <div
          style={{
            marginTop: 10,
            width: "100%",
          }}>
          <PanelBar style={{ border: "1px solid #9e9e9e" }}>
            <PanelBarItem expanded={true} title={"검색 조건"}>
              <div style={{ padding: "0.5%" }}>
                <div
                  style={{
                    fontSize: 15,
                    fontFamily: "NotoSansKR-Bold",
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                    alignItems: "center",
                  }}>
                  <Flex style={{ width: "50%" }}>
                    <FiltersLabel>기간</FiltersLabel>
                    <Flex style={{ alignItems: "center" }}>
                      <SelectBox
                        value={state?.dateType}
                        onChange={(e) => {
                          handleValueChange("dateType", e.target.value);
                        }}>
                        <option value="CREATED">{"거래일"}</option>
                        <option value="UPDATED">{"정산일"}</option>
                      </SelectBox>
                      &nbsp;
                      <Input
                        style={{ width: 150 }}
                        type="date"
                        value={state?.fromDate ? state.fromDate : ""}
                        onChange={(e) => {
                          handleValueChange("fromDate", e.target.value);
                        }}
                      />
                      &nbsp; ~ &nbsp;
                      <Input
                        style={{ width: 150 }}
                        type="date"
                        value={state?.toDate ? state?.toDate : ""}
                        onChange={(e) => {
                          handleValueChange("toDate", e.target.value);
                        }}
                      />
                    </Flex>
                  </Flex>
                  <Flex style={{ width: "50%" }}>
                    <FiltersLabel>정산여부</FiltersLabel>
                    {checkSettleStatusData.map((item, index) => {
                      const { value: checkValue, label } = item;
                      return (
                        <div style={{ marginRight: 30, fontSize: 16 }}>
                          <Checkbox
                            key={index.toString()}
                            // defaultChecked={state.settleStatus}
                            checked={settleStatusCheckedList.includes(
                              checkValue
                            )}
                            onChange={(e) => {
                              if (e.target.value) {
                                handleValueChange("settleStatus", [
                                  ...settleStatusCheckedList,
                                  checkValue,
                                ]);
                              } else {
                                handleValueChange(
                                  "settleStatus",
                                  settleStatusCheckedList.filter(
                                    (e) => e !== checkValue
                                  )
                                );
                              }
                            }}
                            label={label}
                          />
                        </div>
                      );
                    })}
                    {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
                    {/* <Checkbox
                        value={state?.settleStatus === "DONE"}
                        onChange={() => {
                          handleValueChange("settleStatus", "DONE");
                        }}
                        label={"완료"}
                      />
                      <Checkbox
                        value={state?.settleStatus === "NEW"}
                        onChange={() => {
                          handleValueChange("settleStatus", "NEW");
                        }}
                        label={"미완료"}
                      />
                      <Checkbox
                        value={state?.settleStatus === "CANCEL"}
                        onChange={() => {
                          handleValueChange("settleStatus", "CANCEL");
                        }}
                        label={"취소"}
                      /> */}
                    {/* </div> */}
                  </Flex>
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: 10,
                  }}>
                  <Button
                    className="buttons-container-button"
                    icon="search"
                    style={{ marginRight: 5 }}
                    onClick={fetchList}>
                    검색
                  </Button>
                  <Button
                    className="buttons-container-button"
                    icon="refresh"
                    onClick={initState}>
                    초기화
                  </Button>
                </div>
              </div>
            </PanelBarItem>
          </PanelBar>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <div
            style={{
              display: "flex",
              fontSize: 16,
              fontFamily: "NotoSansKR-Bold",
            }}>
            <div
              style={{ display: "flex", marginRight: 10, fontWeight: "bold" }}>
              총 거래금액 :{numberWithCommas(sumTradeKrw)} 원
            </div>
            <div style={{ display: "flex", fontWeight: "bold" }}>
              총 수수료 : {numberWithCommas(sumTotal)} 원 (공급가 :
              {numberWithCommas(sumSupply)} 원 / 부가세 :
              {numberWithCommas(sumVat)} 원)
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
          }}>
          <LocalizationProvider language="es-KR" style={{ height: "100%" }}>
            <IntlProvider locale="es" style={{ height: "100%" }}>
              <Grid
                style={{
                  width: "100%",
                  marginTop: 5,
                  cursor: "pointer",
                  fontSize: "16px",
                  height: "100%",
                  maxHeight: "1050px",
                }}
                data={
                  shopFees?.length !== 0
                    ? shopFees
                        ?.slice(
                          state.page * state.size,
                          state.page * state.size + state.size
                        )
                        ?.map((item, index) => ({
                          ...item,
                          // no:
                          //   totalCount -
                          //   parseInt(state.page) * parseInt(state.size) -
                          //   index,
                          no: createIndex(state, index),
                          selected: selectedState[idGetter(item)],
                        }))
                    : []
                }
                skip={state.page * state.size}
                take={state.size}
                total={totalCount}
                resizable={true}
                pageable={{
                  pageSizes: [10, 30, 50, 100],
                  previousNext: true,
                  info: true,
                }}
                selectable={{
                  enabled: true,
                }}
                selectedField="selected"
                onSelectionChange={onSelectionChange}
                onKeyDown={onKeyDown}
                scrollable={"scrollable"}
                navigatable={true}
                dataItemKey={DATA_ITEM_KEY}
                onPageChange={pageChange}
                cellRender={cellRender}>
                <GridColumn field="no" title="번호" width={100} />
                <GridColumn field="fromDate" title="from" width={140} />
                <GridColumn field="toDate" title="to" width={140} />
                <GridColumn title="정산여부" field="status" width={160} />
                <GridColumn title="정산일시" field="updatedAt" width={160} />
                <GridColumn title="수수료" field="totalFee" width={150} />
                <GridColumn title="지체상금" field="penaltyKrw" width={150} />
                <GridColumn title="감면액" field="discountKrw" width={150} />
                <GridColumn title="정산금" field="settleKrw" width={150} />
                <GridColumn title="부가세" field="vat" width={150} />
                <GridColumn title="합계" field="settleAmount" width={150} />
                <GridColumn field="tradeKrw" title="거래금액" width={150} />
                <GridColumn field="bankName" title="은행명" width={140} />
                <GridColumn
                  field="accountNumber"
                  title="정산계좌"
                  width={180}
                />
                {/* <GridColumn title="공급가">
                  {supplyFee.map((fee) => {
                    return (
                      <GridColumn
                        title={fee.label}
                        field={fee.key}
                        width={150}
                      />
                    );
                  })}
                </GridColumn> */}
                {/* <GridColumn title="총 수수료" width={150}>
                  {totalFee.map((fee) => {
                    return (
                      <GridColumn
                        title={fee.label}
                        field={fee.key}
                        width={150}
                      />
                    );
                  })}
                </GridColumn> */}
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
        </div>
      </Wrap>
    </DefaultLayout>
  );
};

export default SettlementManagement;
