import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import moment from "moment-timezone";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";

import DefaultLayout from "components/layout/DefaultLayout";
import Flex from "components/layout/Flex";
import Button from "components/button/Button";
import * as APIS from "../../libs/apis";
import { formatTime, numberWithCommas, objToQueryStr } from "libs/utils";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "store/loadingReducer";
import AppraisalDetail from "pages/appraisal/AppraisalDetail";

const assetTypes = [
  { label: "금", value: "GOLD" },
  { label: "은", value: "SILVER" },
];

const FiltersLabel = styled.label`
  margin-left: 10px;
  /* width: 100px; */
  font-size: 16px;
  font-weight: bold;
  align-self: center;
`;

const SmallTitle = styled(Flex)`
  font-weight: bold;
`;

function Penalty() {
  const location = useLocation();
  const state = location.state || {};
  const dispatch = useDispatch();
  const authReducer = useSelector((s) => s.auth);
  let { screenType } = authReducer;
  const isShop = screenType === "SHOP";

  const [filterExpanded, setFilterExpanded] = useState(true);
  const [condition, setCondition] = useState({
    fromDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [dataList, setDataList] = useState([]);
  const [penaltyDetail, setPenaltyDetail] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [detailComponent, setDetailComponent] = useState(null);
  const [appraisalId, setAppraisalId] = useState(0);

  const onClickDay = (name) => {
    switch (name) {
      case "day":
        setCondition({
          fromDate: moment(new Date()).format("YYYY-MM-DD"),
          toDate: moment(new Date()).format("YYYY-MM-DD"),
        });
        break;
      case "week":
        setCondition({
          fromDate: moment().subtract(1, "week").format("YYYY-MM-DD"),
          toDate: moment(new Date()).format("YYYY-MM-DD"),
        });
        break;
      case "1m":
        setCondition({
          fromDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
          toDate: moment(new Date()).format("YYYY-MM-DD"),
        });
        break;
      case "3m":
        setCondition({
          fromDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
          toDate: moment(new Date()).format("YYYY-MM-DD"),
        });
        break;
    }
  };

  const toggleDialog = (id, appraisalIds) => {
    if (appraisalIds.length === 0) {
      setPenaltyDetail([]);
      return;
    }
    setSelectedRow(id);
    let ids = appraisalIds.split(", ").map((item) => parseInt(item));

    dispatch(showLoading());
    APIS.getAppraisalPenaltyDetail(
      objToQueryStr({
        appraisalIds: appraisalIds,
      })
    )
      .then(({ data: { data, success, message } }) => {
        if (success) {
          setPenaltyDetail(data);
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  const fetchList = () => {
    dispatch(showLoading());
    const api = isShop
      ? APIS.getAppraisalPenalty(objToQueryStr(condition))
      : APIS.getBizDirectPenalty(objToQueryStr(condition));
    api
      .then(({ data: { data, success, message } }) => {
        if (success) {
          setDataList(
            data.map((value, i) => {
              return {
                ...value,
                no: data.length - (data.length - 1 - i),
              };
            })
          );
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  const clearDetail = () => {
    setDetailComponent(null);
  };

  const initState = () => {
    setCondition({
      fromDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
      toDate: moment(new Date()).format("YYYY-MM-DD"),
    });
  };

  useEffect(() => {
    if (appraisalId !== 0) {
      setDetailComponent(() => {
        return null;
      });

      setDetailComponent(() => {
        return <AppraisalDetail id={[appraisalId]} fetchList={fetchList} />;
      });
    }
  }, [appraisalId]);

  useEffect(() => {
    fetchList();
  }, []);

  const { fromDate, toDate } = condition;

  return (
    <DefaultLayout detail={detailComponent} clearDetail={clearDetail}>
      <Flex
        style={{
          flexDirection: "column",
          marginBottom: 5,
          minWidth: "1000px",
        }}>
        <PanelBar
          onSelect={() => {
            setFilterExpanded(!filterExpanded);
          }}
          style={{ width: "100%", border: "1px solid #9e9e9e" }}>
          <PanelBarItem expanded={filterExpanded} title={"검색 조건"}>
            <div style={{ padding: "0.5%" }}>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  marginTop: 10,
                }}>
                <Flex
                  style={{
                    fontSize: 15,
                    fontFamily: "NotoSansKR-Bold",
                    flexDirection: "row",
                    display: "flex",
                    lineHeight: 2,
                    width: "100%",
                    alignItems: "center",
                  }}>
                  <FiltersLabel style={{ marginRight: 20 }}>
                    검색 기간
                  </FiltersLabel>
                  <DatePicker
                    width={200}
                    format={"yyyy-MM-dd"}
                    value={
                      fromDate !== "Invalid date" ? new Date(fromDate) : ""
                    }
                    onChange={(e) => {
                      const { value } = e;
                      setCondition({
                        ...condition,
                        fromDate: moment(value).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                  &nbsp;~&nbsp;
                  <DatePicker
                    width={200}
                    format={"yyyy-MM-dd"}
                    value={toDate !== "Invalid date" ? new Date(toDate) : ""}
                    onChange={(e) => {
                      const { value } = e;
                      setCondition({
                        ...condition,
                        toDate: moment(value).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                  <Button
                    style={{
                      marginLeft: 5,
                    }}
                    onClick={() => onClickDay("day")}>
                    당일
                  </Button>
                  <Button
                    style={{
                      marginLeft: 5,
                    }}
                    onClick={() => onClickDay("week")}>
                    1주
                  </Button>
                  <Button
                    style={{
                      marginLeft: 5,
                    }}
                    onClick={() => onClickDay("1m")}>
                    1개월
                  </Button>
                  <Button
                    style={{ marginLeft: 5 }}
                    onClick={() => onClickDay("3m")}>
                    3개월
                  </Button>
                </Flex>
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: 10,
                }}>
                <Button
                  className="buttons-container-button"
                  icon="search"
                  style={{ marginRight: 5 }}
                  onClick={fetchList}>
                  검색
                </Button>
                <Button
                  className="buttons-container-button"
                  icon="refresh"
                  onClick={initState}>
                  초기화
                </Button>
              </div>
            </div>
          </PanelBarItem>
        </PanelBar>
        <LocalizationProvider language="es-KR" style={{ height: "100%" }}>
          <IntlProvider locale="es" style={{ height: "100%" }}>
            <Flex style={{}}>
              <Flex
                style={{ flexDirection: "column", marginRight: 7, flex: 0.7 }}>
                <SmallTitle style={{ padding: "5px" }}>
                  지체상금 목록
                </SmallTitle>
                <Grid
                  data={dataList}
                  scrollable={"scrollable"}
                  style={{
                    height: "100%",
                  }}
                  onRowClick={(e) => {
                    toggleDialog(e.dataItem.no, e.dataItem.appraisalIds);
                  }}
                  rowRender={(tr, props) => {
                    const trProps = {
                      style:
                        props.dataItem.no === selectedRow
                          ? { backgroundColor: "rgba(0,0,0,0.4)" }
                          : {},
                    };
                    return React.cloneElement(
                      tr,
                      { ...trProps },
                      tr.props.children
                    );
                  }}>
                  {/* numberWithCommas */}
                  <Column title="No" width={50} field="no" />
                  <Column title="일자" width={100} field="completedDate" />
                  <Column title="대리점" width={100} field="shopName" />
                  <Column
                    title="지체상금"
                    width={100}
                    field="penaltyKrw"
                    cell={(props) => {
                      const { dataItem } = props;
                      return (
                        <td style={{ textAlign: "right" }}>
                          {numberWithCommas(dataItem.penaltyKrw)}
                        </td>
                      );
                    }}
                  />
                  <Column
                    title="지체 감정건"
                    width={100}
                    field="penaltyCount"
                    cell={(props) => {
                      const { dataItem } = props;
                      return (
                        <td style={{ textAlign: "center" }}>
                          {numberWithCommas(dataItem.penaltyCount)}
                        </td>
                      );
                    }}
                  />
                </Grid>
              </Flex>
              <Flex style={{ flexDirection: "column", flex: 1.3 }}>
                <SmallTitle style={{ padding: "5px" }}>
                  지체상금 내역
                </SmallTitle>
                <Grid
                  data={penaltyDetail}
                  scrollable={"scrollable"}
                  rowRender={(tr, props) => {
                    const trProps = {
                      style:
                        props.dataItem.id === appraisalId
                          ? { backgroundColor: "rgba(0,0,0,0.4)" }
                          : {},
                    };
                    return React.cloneElement(
                      tr,
                      { ...trProps },
                      tr.props.children
                    );
                  }}>
                  <Column
                    title="접수번호"
                    field="number"
                    cell={(props) => {
                      const { dataItem } = props;
                      return (
                        <td
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: "blue",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            setAppraisalId(dataItem.id);
                          }}>
                          {dataItem.number}
                        </td>
                      );
                    }}
                  />
                  <Column
                    title="자산"
                    field="assetType"
                    width={80}
                    cell={(props) => {
                      const dataItem = props.dataItem;
                      return (
                        <td style={{ textAlign: "center" }}>
                          {dataItem.assetType
                            ? assetTypes.find(
                                (type) => type.value === dataItem.assetType
                              ).label
                            : "-"}
                        </td>
                      );
                    }}
                  />
                  <Column
                    title="중량(g)"
                    field="appraisalWeightGram"
                    width={100}
                    cell={(props) => {
                      const dataItem = props.dataItem;
                      return (
                        <td style={{ textAlign: "center" }}>
                          {numberWithCommas(dataItem.appraisalWeightGram)}
                          {" g"}
                        </td>
                      );
                    }}
                  />
                  <Column
                    title="교환일"
                    field="completedAt"
                    width={150}
                    cell={(props) => {
                      const dataItem = props.dataItem;
                      return (
                        <td style={{ textAlign: "center" }}>
                          {formatTime(dataItem.completedAt, "YYYY-MM-DD")}
                        </td>
                      );
                    }}
                  />
                  <Column
                    title="입고 상태"
                    field="stockStatus"
                    width={100}
                    cell={(props) => {
                      const dataItem = props.dataItem;
                      return (
                        <td style={{ textAlign: "center" }}>
                          {dataItem.stockStatus}
                        </td>
                      );
                    }}
                  />
                  <Column
                    title="입고 마감일"
                    field="deadlineStockDt"
                    width={150}
                    cell={(props) => {
                      const dataItem = props.dataItem;
                      return (
                        <td style={{ textAlign: "center" }}>
                          {dataItem.deadlineStockDt}
                        </td>
                      );
                    }}
                  />
                </Grid>
              </Flex>
            </Flex>
          </IntlProvider>
        </LocalizationProvider>
      </Flex>
    </DefaultLayout>
  );
}

export default Penalty;
