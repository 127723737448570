import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React from "react";
import styled from "styled-components";
import * as APIS from "../../libs/apis";
import { useDispatch } from "react-redux";
import { showAlert } from "store/alertReducer";
import { hideLoading, showLoading } from "store/loadingReducer";

const BizTransactionModal = ({
  bizTransactionVisible,
  setBizTransactionVisible,
  bizTradeSelectType,
  bizOrderType,
  detailData,
  bizPurchasePrice,
}) => {
  const dispatch = useDispatch();

  const modalTitle =
    bizTradeSelectType === "CANCEL"
      ? "주문 취소"
      : bizTradeSelectType === "ORDER"
      ? "주문 요청"
      : bizTradeSelectType === "WITHDRAW"
      ? "출금 요청"
      : "";

  const handleClickClose = () => {
    setBizTransactionVisible(false);
  };

  const handleClickTrade = () => {
    const orderPrice =
      bizOrderType === "RECOMMEND"
        ? bizPurchasePrice.directSuggestDon
        : bizPurchasePrice.directMarketDon;

    // 취소 데이터
    const cancelData = {
      shopId: detailData.shop.id,
      userId: detailData.user.id,
      requestId: detailData.id,
    };
    //주문 데이터
    const orderData = {
      shopId: detailData.shop.id,
      userId: detailData.user.id,
      requestId: detailData.id,
      bizServiceType: bizOrderType
        .replace("RECOMMEND", "DIRECT_SUGGEST")
        .replace("MARKET", "DIRECT_MARKET"),
      orderPrice: orderPrice,
    };
    //출금요청 데이터
    const withdrawData = {
      requestId: detailData.id,
      userId: detailData.user.id,
      withdrawPrice: detailData.availableWithdrawal, // 출금 요청 금액(수수료 뺀금액)
    };
    const api =
      bizTradeSelectType === "CANCEL"
        ? APIS.patchDirectCancel(cancelData)
        : bizTradeSelectType === "ORDER"
        ? APIS.patchDirectOrder(orderData)
        : bizTradeSelectType === "WITHDRAW"
        ? APIS.postDirectWithdraw(withdrawData)
        : "";

    dispatch(showLoading());
    api
      .then(({ data: { success, data, message } }) => {
        if (success) {
          dispatch(showAlert({ message: "처리되었습니다." }));
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .finally(() => {
        dispatch(hideLoading());
        setBizTransactionVisible(false);
      });
  };

  const render = () => {
    switch (bizTradeSelectType) {
      case "CANCEL":
        return (
          <div>
            <p>주문을 취소하시겠어요?</p>
            <p>주문을 취소하시면 전량 주문 취소됩니다.</p>
          </div>
        );
      case "ORDER":
        return (
          <div>
            <div>{`${bizOrderType
              .replace("MARKET", "시장가")
              .replace("RECOMMEND", "추천가")}로 재주문 하시겠어요?`}</div>
          </div>
        );
      case "WITHDRAW":
        return (
          <div>
            <p>출금하시겠어요?</p>
            <p>출금요청 금액 : {detailData.availableWithdrawal}</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    bizTransactionVisible && (
      <Dialog
        onClose={handleClickClose}
        title={modalTitle}
        visible={bizTransactionVisible}
        style={{ zIndex: 200 }}>
        <Wrapper>
          <Content>{render()}</Content>
        </Wrapper>
        <DialogActionsBar>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={handleClickClose}>
            닫기
          </button>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={handleClickTrade}>
            확인
          </button>
        </DialogActionsBar>
      </Dialog>
    )
  );
};

export default BizTransactionModal;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0px 20px 20px 20px;
`;

const Content = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
margin-top: 20px;
`;
