import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AdminConfirmButton from "../../components/button/AdminConfirmButton";
import * as APIS from "../../libs/apis";
import { showLoading, hideLoading } from "../../store/loadingReducer";
import { hideAlert, showAlert } from "../../store/alertReducer";
import { useDispatch, useSelector } from "react-redux";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { formatPhone, formatTime } from "../../libs/utils";
import Button from "../../components/button/Button";

const Table = styled.table`
  width: 100%;
  height: 150px;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  border-collapse: collapse;
  margin-top: 5px;
`;

const Th = styled.th`
  background: #f2f4f6;
  text-align: left;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #636a75;
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
`;

const Td = styled.td`
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
  padding: 10px;
`;

const ModalInputWarning = styled.input`
  display: inline-block;
  color: #e52528;
  border: 2px solid #e52528;
  transition: all 0.5s;
  min-width: 200;
  align-items: "center";
  justify-content: "center";
  border-radius: 3;
  padding: "0 10px";
  min-height: 20;
`;
const ModalInput = styled.input`
  float: left;
  border: 1px solid #dddddd;
  min-width: 200;
  display: "flex";
  align-items: "center";
  justify-content: "center";
  border-radius: 3;
  padding: "0 10px";
  min-width: 20;
`;

const Input = styled.input`
  width: ${(props) => (props.width ? props.width : "250px")};
  height: 35px;
  border-radius: 0.2rem;
  border: 1px solid #ededed;
  padding-left: 10px;
  &:focus {
    outline: 1px solid #4583ee;
    box-shadow: 0px 0px 2px red;
  }
`;

const MyInfo = () => {
  const dispatch = useDispatch();
  const authReducer = useSelector((s) => s.auth);
  let { screenType } = authReducer;
  const isShop = screenType === "SHOP";
  const [manager, setManager] = useState({});
  const [recentPassword, setRecentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const id = localStorage.getItem("id");
  const [pwdCheckSwitch, setpwdCheckSwitch] = useState(true);
  const [authGroupList, setAuthGroupList] = useState([]);
  const [shopList, setShopList] = useState([]);

  const onChangeValue = ({ target }) => {
    const { name, value } = target;
    setPassword(value);
    if (name === "password") {
      if (newPassword !== value) {
        setpwdCheckSwitch(false);
      } else {
        setpwdCheckSwitch(true);
        setPassword(value);
      }
    }
  };

  const handleEditAdmin = async (adminPassword) => {
    const data = {
      id: id,
      name: manager.name,
      phone: manager.phone,
      groupId: manager.groupId,
      shopId: manager.shopId,
      managerPassword: adminPassword,
      status: "ACTIVE",
    };
    dispatch(showLoading());
    const api = isShop ? APIS.putEditMyInfo(data) : APIS.putBizEditMyPage(data);
    api
      .then(({ data: { success, data, message } }) => {
        if (success) {
          dispatch(
            showAlert({
              message: "내 정보가 수정되었습니다.",
              onClick: () => {
                setManager(data);
              },
            })
          );
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  const handleModifyPassword = () => {
    if (!recentPassword) {
      dispatch(showAlert({ message: "현재 비밀번호를 입력해 주세요." }));
    } else if (!password) {
      dispatch(showAlert({ message: "새 비밀번호를 입력해 주세요." }));
    } else if (pwdCheckSwitch === false) {
      dispatch(
        showAlert({
          message: "새 비밀번호와 비밀번호 확인이 \n 일치하지 않습니다.",
        })
      );
    } else {
      dispatch(showLoading());
      const api = isShop
        ? APIS.putModifyMyPassword({
            managerPassword: recentPassword,
            password: password,
          })
        : APIS.putBizModifyMyPassword({
            managerPassword: recentPassword,
            password: password,
          });
      api
        .then(({ data: { success, message } }) => {
          if (success) {
            dispatch(
              showAlert({
                title: "",
                message: "비밀번호가 변경되었습니다.",
                onClick: () => {
                  init();
                },
              })
            );
          } else {
            dispatch(
              showAlert({
                title: "",
                message: message,
              })
            );
          }
        })
        .catch(() => {
          dispatch(showAlert({ message: "비밀번호 변경에 실패하셨습니다." }));
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    }
  };

  const getAdminDetail = () => {
    dispatch(showLoading());
    const api = isShop ? APIS.getAdminInfo() : APIS.getBizMyPage();
    api
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setManager(data);
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((error) =>
        dispatch(showAlert({ message: error.response.data.message }))
      )
      .finally(() => dispatch(hideLoading()));
  };

  //   const authorityGroupList = () => {
  //     dispatch(showLoading());
  //     APIS.getAuthorityGroupList()
  //       .then(({ data: { data, message, success } }) => {
  //         if (success) {
  //           setAuthGroupList(data);
  //         } else {
  //           dispatch(showAlert({ message: message }));
  //         }
  //       })
  //       .catch((error) => dispatch(showAlert({ message: error })))
  //       .finally(() => dispatch(hideLoading()));
  //   };

  //   const getShopList = () => {
  //     dispatch(showLoading());
  //     APIS.getShopList()
  //       .then(({ data: { data, message, success } }) => {
  //         if (success) {
  //           setShopList(data);
  //         } else {
  //           dispatch(showAlert({ message: message }));
  //         }
  //       })
  //       .catch((error) => dispatch(showAlert({ message: error })))
  //       .finally(() => dispatch(hideLoading()));
  //   };

  useEffect(() => {
    getAdminDetail();
    // getShopList();
    // authorityGroupList();
  }, []);

  const init = () => {
    setPassword("");
    setNewPassword("");
    setRecentPassword("");
  };
  return (
    <div style={{ padding: "10px 20px 0px 20px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}>
        <div
          style={{
            alignSelf: "center",
            fontWeight: "bold",
            fontSize: 16,
            fontFamily: "NotoSansKR-Bold",
          }}>
          내 정보
        </div>
        <div>
          <AdminConfirmButton
            title="내 정보 수정"
            callback={handleEditAdmin}
            style={{
              fontWeight: "bold",
              fontSize: 16,
              fontFamily: "NotoSansKR-Bold",
            }}
          />
        </div>
      </div>
      <Table>
        <colgroup>
          <col width="3%" />
          <col width="20%" />
        </colgroup>
        <tbody>
          <tr>
            <Th>그룹</Th>
            <Td>{manager.groupName || "-"}</Td>
          </tr>
          <tr>
            <Th>대리점</Th>
            <Td>{manager.shopName || "-"}</Td>
          </tr>
          <tr>
            <Th>계정 ID</Th>
            <Td>{manager.account || "-"}</Td>
          </tr>
          <tr>
            <Th>이름</Th>
            <Td>
              <Input
                style={{
                  minWidth: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid rgba(0,0,0,0.23)",
                  borderRadius: 3,
                  padding: "0 10px",
                  minHeight: 20,
                }}
                value={manager.name}
                onChange={({ target: { value } }) => {
                  setManager((manager) => ({
                    ...manager,
                    name: value,
                  }));
                }}
              />
            </Td>
          </tr>
          <tr>
            <Th>휴대전화</Th>
            <Td>
              <Input
                style={{
                  minWidth: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid rgba(0,0,0,0.23)",
                  borderRadius: 3,
                  padding: "0 10px",
                  minHeight: 20,
                }}
                value={manager.phone}
                onChange={({ target: { value } }) => {
                  setManager((manager) => ({
                    ...manager,
                    phone: value,
                  }));
                }}
              />
            </Td>
          </tr>
          <tr>
            <Th>현재 비밀번호</Th>
            <Td>
              <Input
                style={{
                  minWidth: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid rgba(0,0,0,0.23)",
                  borderRadius: 3,
                  padding: "0 10px",
                  minHeight: 20,
                }}
                type="password"
                autoComplete="new-password"
                value={recentPassword}
                onChange={({ target: { value } }) => {
                  setRecentPassword(value);
                }}
              />
            </Td>
          </tr>
          <tr>
            <Th>새 비밀번호</Th>
            <Td>
              <Input
                style={{
                  minWidth: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid rgba(0,0,0,0.23)",
                  borderRadius: 3,
                  padding: "0 10px",
                  minHeight: 20,
                }}
                type="password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </Td>
          </tr>
          <tr>
            <Th>새 비밀번호 확인</Th>
            <Td>
              <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {pwdCheckSwitch ? (
                    <Input
                      value={password}
                      type="password"
                      name="password"
                      style={{
                        float: "left",
                        border: "1px solid #dddddd",
                        minWidth: 200,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 3,
                        padding: "0 10px",
                        minHeight: 20,
                      }}
                      onChange={(e) => {
                        onChangeValue(e);
                      }}
                    />
                  ) : (
                    <Input
                      value={password}
                      type="password"
                      name="password"
                      style={{
                        display: "inline-block",
                        color: "#e52528",
                        border: "2px solid #e52528",
                        transition: "all 0.5s",
                        minWidth: 200,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 3,
                        padding: "0 10px",
                        minHeight: 20,
                      }}
                      onChange={(e) => {
                        onChangeValue(e);
                      }}
                    />
                  )}
                  <Button
                    style={{ marginLeft: 5 }}
                    onClick={handleModifyPassword}>
                    비밀번호 변경
                  </Button>
                </div>
                {pwdCheckSwitch ? null : (
                  <div style={{ color: "#e52528", justifySelf: "left" }}>
                    비밀번호가 일치하지 않습니다. 다시 확인해주세요.
                  </div>
                )}
              </div>
            </Td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default MyInfo;
