import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  GridColumn,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import { NumericTextBox, RadioButton } from "@progress/kendo-react-inputs";
import * as APIS from "../../libs/apis";
import { showAlert } from "store/alertReducer";
import { hideLoading, showLoading } from "store/loadingReducer";
import { useDispatch, useSelector } from "react-redux";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import cloneDeep from "lodash.clonedeep";
import { numberWithCommas } from "libs/utils";
import Modal from "components/common/Modal";
import styled from "styled-components";
import { MdOutlineCancel } from "react-icons/md";
import Flex from "components/layout/Flex";

const configMapping = [
  {
    category: "DIRECT_MARKET",
    name: "다이렉트 시장가",
    keys: [
      "directShopFeePercent", // 다이렉트 예상 대리점 수수료율
      "expectedDirectMarketShop", // 다이렉트 시장가 대리점 지급 예상 금액
      "expectedDirectMarketCustomer", // 다이렉트 시장가 고객 지급 예상 금액
    ],
  },
  {
    category: "DIRECT_SUGGEST",
    name: "다이렉트 추천가",
    keys: [
      "directShopFeePercent", // 다이렉트 예상 대리점 수수료
      "expectedDirectSuggestShop", // 다이렉트 추천가 대리점 지급 예상 금액
      "expectedDirectSuggestCustomer", // 다이렉트 추천가 고객 지급 예상 금액
    ],
  },
  {
    category: "CONSIGNMENT",
    name: "위탁매입",
    keys: [
      "currentConsignmentShop", // 위탁매입 대리점 수수료(본사 관리자 입력 - 원/돈)
      "currentConsignmentPrice", // 위탁매입 시세(본사 관리자 입력 - 원/돈)
      "expectedConsignmentShop", // 위탁매입 예상 대리점 지급 금액
      "expectedConsignmentCustomer", // 위탁매입 예상 고객 지급 금액
    ],
  },
];

const keyWordMapping = {
  directShopFeePercent: "예상 대리점 수수료율 (%)",
  expectedMarketTradeKrw: "판매 예상 금액 (원)",
  expectedMarketCustomerFee: "예상 수수료",
  expectedDirectMarketShop: "예상 대리점 수수료 (원)",
  expectedDirectMarketCustomer: "고객 지급 예상 금액 (원)",
  directSuggestGram: "다이렉트 추천가 1g 가격",
  directSuggestDon: "다이렉트 추천가 1돈 가격",
  expectedSuggestTradeKrw: "다이렉트 추천가 판매 예상 금액",
  expectedSuggestCustomerFee: "다이렉트 추천가 예상 수수료",
  expectedDirectSuggestShop: "대리점 수수료 (원)",
  expectedDirectSuggestCustomer: "고객 지급 금액 (원)",
  currentConsignmentShop: "수수료 (원/돈)",
  currentConsignmentPrice: "시세 (원)",
  expectedConsignmentShop: "대리점 수수료 (원)",
  expectedConsignmentCustomer: "고객 지급 금액 (원)",
};
const AppraisalStep = ({ userId, setBizRequestVisible }) => {
  const dispatch = useDispatch();
  const authReducer = useSelector((s) => s.auth);
  let { shopId } = authReducer;
  const [assetType, setAssetType] = useState("GOLD");
  const [productList, setProductList] = useState([]);
  const [totalGram, setTotalGram] = useState(0);
  const [valueModalVisible, setValueModalVisible] = useState(false);
  const [purchasePriceData, setPurchasePriceData] = useState({});
  const assetKey = assetType === "GOLD" ? "goldProduct" : "silverProduct";

  useEffect(() => {
    dispatch(showLoading());
    APIS.getPurchaseProductList()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          assetType === "GOLD"
            ? setProductList(data.goldProduct)
            : setProductList(data.silverProduct);
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((e) => {
        showAlert({ message: e.response.data.message });
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  }, [assetType]);

  const NumberInputCell = useCallback((props) => {
    const { dataIndex, dataItem, field } = props;
    const onChangeValue = (e) => {
      if (!!props.onChange) {
        props.onChange({
          dataIndex,
          value: e.value,
          field,
          syntheticEvent: e.syntheticEvent,
          dataItem,
        });
      }
    };
    return (
      <td style={{ textAlign: "-webkit-center" }}>
        <tr>
          <NumericTextBox
            onChange={onChangeValue}
            id={`${field}-${dataIndex}`}
            name={`${field}`}
            width={100}
            size="small"
            spinners={false}
            min={0}
            fillMode={"outline"}
            inputStyle={{ backgroundColor: "white" }}
          />
        </tr>
      </td>
    );
  }, []);

  const basicPctCell = useCallback((props) => {
    const { dataIndex, dataItem, field } = props;
    const onChangeValue = (e) => {
      if (!!props.onChange) {
        props.onChange({
          dataIndex,
          value: e.value,
          field,
          syntheticEvent: e.syntheticEvent,
          dataItem,
        });
      }
    };
    const list = cloneDeep(productList);
    return (
      <td style={{ textAlign: "-webkit-center" }}>
        <tr>
          {list?.[dataIndex]?.isWeightRateChange === true ? (
            <div style={{ display: "flex" }}>
              <NumericTextBox
                value={dataItem.basicPct}
                onChange={onChangeValue}
                id={`basicPct-${dataIndex}`}
                name={"basicPct"}
                width={100}
                size="small"
                spinners={false}
                min={0}
                fillMode={"outline"}
                inputStyle={{ backgroundColor: "white" }}
              />
              <div
                style={{
                  alignSelf: "center",
                  fontSize: "medium",
                }}>
                %
              </div>
            </div>
          ) : list?.[dataIndex]?.basicPct ? (
            `${numberWithCommas(list?.[dataIndex]?.basicPct)}%`
          ) : (
            "-"
          )}
        </tr>
      </td>
    );
  }, []);

  const cellRender = (cell, props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    if (
      props.field === "createdAt" ||
      props.field === "status" ||
      props.field === "managerName" ||
      props.field === "assetType" ||
      props.field === "purityTypeName" ||
      props.field === "name"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}>
          {props.dataItem[props.field]}
        </td>
      );
    } else if (
      props.field === "appraiseGram" ||
      props.field === "basicPct" ||
      props.field === "productWeightGram" ||
      props.field === "appraisalWeightGram"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "right" }}>
          {numberWithCommas(props.dataItem[props.field])}
        </td>
      );
    }
    return cell;
  };

  const onItemChange = useCallback(
    (props) => {
      const { value, dataItem, field } = props;
      let copyData = cloneDeep(productList);
      const updatedItems = copyData.map((item) => {
        const basicPctValue = field === "basicPct" ? value : item.basicPct;
        const productWeightGramValue =
          field === "productWeightGram" ? value : item.productWeightGram;
        if (item.id === dataItem.id) {
          return {
            ...item,
            [field]: value,
            basicPct: basicPctValue,
            productWeightGram: productWeightGramValue,
            appraisalWeightGram:
              dataItem.assetType === "GOLD"
                ? calculateAppraisalWeight(
                    Math.floor(
                      Math.round(
                        Number(
                          (
                            (basicPctValue / 100) *
                            productWeightGramValue
                          ).toFixed(13)
                        ) * 10000
                      ) / 10
                    ) / 1000
                  )
                : calculateAppraisalWeight(
                    Math.floor(
                      Math.round(
                        Number(
                          (
                            (basicPctValue / 100) *
                            productWeightGramValue
                          ).toFixed(13)
                        ) * 10000
                      ) / 1000
                    ) / 10
                  ),
          };
        } else {
          return item;
        }
      });

      copyData = updatedItems;
      setProductList(copyData); // 복사본 데이터 업데이트

      //환산중량 총 합계
      const totalWeight = updatedItems.reduce((acc, item) => {
        return Number(
          (Number(acc) + Number(item.appraisalWeightGram)).toFixed(13)
        );
      }, 0);
      setTotalGram(totalWeight);
    },
    [assetType, productList]
  );
  // 환산중량 계산 함수
  const calculateAppraisalWeight = (num) => {
    let stringAppraisalWeightGram = String(num);

    // replaceAt 메서드 구현
    const replaceAt = (str, index, replacement) => {
      return str.substring(0, index) + replacement + str.substring(index + 1);
    };

    if (stringAppraisalWeightGram.includes(".")) {
      const numberDigits = stringAppraisalWeightGram.split(".")[1].length;
      if (
        (assetType === "GOLD" && numberDigits === 3) ||
        (assetType === "SILVER" && numberDigits === 1)
      ) {
        const lastNumber =
          stringAppraisalWeightGram[stringAppraisalWeightGram.length - 1];
        if (0 <= parseInt(lastNumber) && parseInt(lastNumber) <= 4) {
          stringAppraisalWeightGram = replaceAt(
            stringAppraisalWeightGram,
            stringAppraisalWeightGram.length - 1,
            "0"
          );
        } else if (5 <= parseInt(lastNumber) && parseInt(lastNumber) <= 9) {
          stringAppraisalWeightGram = replaceAt(
            stringAppraisalWeightGram,
            stringAppraisalWeightGram.length - 1,
            "5"
          );
        }
      }
    }
    return stringAppraisalWeightGram;
  };

  const handleOpenSelectValue = () => {
    const data = {
      shopId: shopId,
      assetType: assetType,
      totalWeightGram: totalGram, // 환산 총 중량
      purchaseProduct: productList[assetKey]
        ?.filter((x) => x.productWeightGram !== null)
        .map((item) => {
          return {
            productId: item.id, // 품목id
            purityType: item.purityType,
            purityTypeName: item.purityTypeName, // 구분
            name: item.name, // 품목
            basicPct: item.basicPct, // 기준비율
            productWeightGram: item.productWeightGram, // 측정 실중량
            appraisalWeightGram: item.appraisalWeightGram, // 환산 중량
            etcFeeKrw: item.etcFeeKrw, // 추가 차감
          };
        }),
    };
    dispatch(showLoading());
    APIS.postPurchasePrice(data)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setPurchasePriceData(data);
          setValueModalVisible(true);
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };
  return (
    <div>
      <div style={{ display: "flex", marginTop: 10, alignItems: "center" }}>
        <span
          style={{
            fontWeight: "bold",
            marginRight: 10,
          }}>
          자산타입
        </span>
        <RadioButton
          style={{
            color: "black",
          }}
          value={"GOLD"}
          label="금"
          checked={assetType === "GOLD"}
          onChange={(e) => {
            setAssetType(e.value);
          }}
        />
        <RadioButton
          style={{
            color: "black",
            marginLeft: 10,
          }}
          value={"SILVER"}
          label="은"
          checked={assetType === "SILVER"}
          onChange={(e) => {
            setAssetType(e.value);
          }}
        />
      </div>
      <div
        style={{
          fontWeight: "bold",
          alignSelf: "center",
          marginRight: 20,
        }}>
        환산 중량 총 합계:
        {`${totalGram && numberWithCommas(totalGram)}g`}
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={handleOpenSelectValue}>
          입력 완료
        </button>
      </div>
      <Grid
        style={{
          width: "100%",
          marginTop: 5,
        }}
        scrollable={"none"}
        data={productList || []}
        cellRender={cellRender}
        onItemChange={onItemChange}>
        <GridColumn title="공통">
          <GridColumn field="purityTypeName" title="구분" width={130} />
          <GridColumn field="name" title="품목" width={140} />
          <GridColumn
            field="basicPct"
            title="기준비율"
            cell={basicPctCell}
            width={120}
          />
          <GridColumn
            field="productWeightGram"
            title="측정 실중량(g)"
            cell={NumberInputCell}
          />
          <GridColumn
            field="appraisalWeightGram"
            title="환산 중량(g)"
            cell={(props) => {
              const { dataIndex } = props;
              return (
                <td style={{ width: 80, textAlign: "right" }}>
                  {productList?.[assetKey]?.[dataIndex]?.appraisalWeightGram !==
                    null &&
                  productList?.[assetKey]?.[dataIndex]?.productWeightGram !==
                    "" &&
                  productList?.[assetKey]?.[dataIndex]?.basicPct !== "" &&
                  productList?.[assetKey]?.[dataIndex]?.productWeightGram !==
                    null
                    ? numberWithCommas(
                        productList?.[assetKey]?.[dataIndex]
                          ?.appraisalWeightGram
                      )
                    : "-"}
                </td>
              );
            }}
          />
        </GridColumn>
        <GridColumn title="위탁매입 전용">
          <GridColumn
            field="etcFeeKrw"
            title="추가차감(돈)"
            cell={NumberInputCell}
          />
          <GridColumn
            field="pureFee"
            title="정제료(돈)"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td>
                  <tr style={{ float: "right" }}>
                    <td> {numberWithCommas(dataItem.pureFee) || "-"}</td>
                  </tr>
                </td>
              );
            }}
          />
          <GridColumn
            field="currentConsignmentPrice"
            title="적용단가(돈)"
            cell={(props) => {
              const { dataItem } = props;
              const calculatedPrice =
                dataItem.currentMarketPrice !== null
                  ? dataItem.currentMarketPrice -
                    (dataItem.pureFee + dataItem.etcFeeKrw)
                  : null;
              return (
                <td style={{ width: 80, textAlign: "right" }}>
                  {calculatedPrice !== null
                    ? numberWithCommas(Math.max(0, calculatedPrice))
                    : "-"}
                </td>
              );
            }}
          />
        </GridColumn>
      </Grid>
      {valueModalVisible && (
        <SelectValueModal
          valueModalVisible={valueModalVisible}
          setValueModalVisible={setValueModalVisible}
          purchasePriceData={purchasePriceData}
          userId={userId}
          productList={productList}
          shopId={shopId}
          setBizRequestVisible={setBizRequestVisible}
        />
      )}
    </div>
  );
};

export default AppraisalStep;

const SelectValueModal = ({
  valueModalVisible,
  setValueModalVisible,
  purchasePriceData,
  userId,
  productList,
  shopId,
  setBizRequestVisible,
}) => {
  const dispatch = useDispatch();
  const [gridData, setGridData] = useState([]);
  const [selected, setSelected] = useState("");
  const [step, setStep] = useState(0);
  const {
    assetType,
    appraisalWeightGram,
    directShopFeePercent,
    directSuggestDon,
    directMarketDon,
    currentConsignmentPrice,
  } = purchasePriceData;
  const assetKey = assetType === "GOLD" ? "goldProduct" : "silverProduct";

  const handleClickPurchase = () => {
    const data = {
      shopId: shopId,
      assetType: assetType,
      totalWeightGram: appraisalWeightGram, // 환산 총 중량
      userId: userId, // 사용자id
      bizServiceType: selected, // 접수 방법
      orderPrice:
        selected === "DIRECT_SUGGEST"
          ? directSuggestDon
          : selected === "DIRECT_MARKET"
          ? directMarketDon
          : currentConsignmentPrice, //시세  - 다이렉트 접수 시 한돈 가격(시장가, 추천가)
      purchaseProduct: productList[assetKey]
        ?.filter((x) => x.productWeightGram !== null)
        .map((item, index) => {
          return {
            seq: index + 1,
            productId: item.id, // 품목id
            purityType: item.purityType,
            purityTypeName: item.purityTypeName, // 구분
            name: item.name, // 품목
            basicPct: item.basicPct, // 기준비율
            productWeightGram: item.productWeightGram, // 측정 실중량
            appraisalWeightGram: item.appraisalWeightGram, // 환산 중량
            etcFeeKrw: item.etcFeeKrw, // 추가 차감
            pureFee: item.pureFee, // 정제료(원/돈)
            currentConsignmentPrice: item.currentConsignmentPrice, // 적용단가(원/돈)
          };
        }),
    };
    dispatch(showLoading());
    APIS.postPurchase(data)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setValueModalVisible(false);
          setBizRequestVisible(false);
          dispatch(showAlert({ message: "접수 완료 되었습니다." }));
        } else {
          setValueModalVisible(false);
          dispatch(showAlert({ message: message }));
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  const handleClickClose = () => {
    setValueModalVisible(false);
  };

  useEffect(() => {
    const transformData = (apiData, configMapping) => {
      const result = configMapping.map((config) => {
        const data = config.keys.map((key) => ({
          key,
          comment: apiData[key],
          value: apiData[key],
        }));
        return {
          category: config.category,
          name: config.name,
          data,
          isConsignment: apiData["isConsignment"],
          isDirectMarket: apiData["isDirectMarket"],
          isDirectSuggest: apiData["isDirectSuggest"],
        };
      });

      return result;
    };
    // 변환된 데이터
    const transformedData = transformData(purchasePriceData, configMapping);
    setGridData(transformedData);
  }, []);

  const customCell = (props, type = "") => {
    const { dataItem } = props;
    const dataArr = dataItem["data"];
    return (
      <td className="td-l">
        {(dataArr || []).map((el, index) => {
          const displayValue =
            type === "key"
              ? keyWordMapping[el?.[type]] || el?.[type]
              : type === "value"
              ? numberWithCommas(el?.[type]) || el?.[type]
              : el?.[type];
          return (
            <tr key={index} style={{ height: "3vh" }}>
              <td
                style={{
                  cursor: "default",
                  width: "100vw",
                  textAlign:
                    type === "type" || type === "configKey" ? "left" : "center",
                  height: "3vh",
                  // background: changed.includes(el.id) ? "#f89a9a" : "none",
                }}>
                {displayValue || "-"}
              </td>
            </tr>
          );
        })}
      </td>
    );
  };
  return (
    <Modal visible={valueModalVisible}>
      <Wrapper>
        <Header>
          <MdOutlineCancel
            onClick={handleClickClose}
            style={{ cursor: "pointer" }}
          />
        </Header>
        <Content>
          {step === 0 ? (
            <div>
              <Grid
                data={gridData}
                style={{
                  width: "100%",
                  marginTop: 5,
                }}
                scrollable={"none"}>
                <GridColumn
                  title="선택"
                  width={130}
                  cell={(e) => {
                    const { dataItem } = e;
                    return (
                      <td>
                        <RadioButton
                          checked={selected === dataItem.category}
                          value={dataItem.category}
                          disabled={
                            dataItem.category === "DIRECT_SUGGEST"
                              ? !dataItem.isDirectSuggest
                              : dataItem.category === "CONSIGNMENT"
                              ? !dataItem.isConsignment
                              : !dataItem.isDirectMarket
                          }
                          onChange={(e) => {
                            setSelected(e.value);
                          }}
                        />
                      </td>
                    );
                  }}
                />
                <GridColumn field="name" title="분류" width={130} />
                <GridColumn
                  title="설명"
                  width={130}
                  cell={(props) => customCell(props, "key")}
                />
                <GridColumn
                  title="값"
                  width={130}
                  cell={(props) => customCell(props, "value")}
                />
              </Grid>
              <Flex>
                <div>
                  <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={() => {
                      setStep(step + 1);
                    }}>
                    다음
                  </button>
                </div>
              </Flex>
            </div>
          ) : (
            <div>
              <div>{`${assetType
                .replace("GOLD", "금")
                .replace(
                  "SILVER",
                  "은"
                )} ${appraisalWeightGram}g 판매합니다.`}</div>
              <div>아래의 내용이 맞나요?</div>
              <div>
                <Flex>
                  <div>자산</div>
                  <div>
                    {assetType.replace("GOLD", "금").replace("SILVER", "은")}
                  </div>
                </Flex>
                <Flex>
                  <div>주문 방식</div>
                  <div>
                    {selected
                      .replace("DIRECT_MARKET", "시장가")
                      .replace("DIRECT_SUGGEST", "추천가")
                      .replace("CONSIGNMENT", "위탁매입")}
                  </div>
                </Flex>
                {selected === "CONSIGNMENT" ? (
                  <Flex>
                    <div>위탁매입 시세 (돈)</div>
                    <div></div>
                  </Flex>
                ) : (
                  <Flex>
                    <div>1g 가격</div>
                    {selected === "DIRECT_MARKET" ? (
                      <div>즉시 체결 가격</div>
                    ) : (
                      <div>
                        <div></div>
                        <div></div>
                      </div>
                    )}
                  </Flex>
                )}
                <Flex>
                  <div>판매 중량</div>
                  <div>{assetType}</div>
                </Flex>
                <Flex>
                  <div>판매 예상 금액</div>
                  <div>{assetType}</div>
                </Flex>
                <Flex>
                  <div>예상 수수료</div>
                  <div>{assetType}</div>
                </Flex>
                <Flex>
                  <div>고객 지급 예상 금액</div>
                  <div>{assetType}</div>
                </Flex>
                <Flex>
                  <div>{`예상 대리점 수수료 (${directShopFeePercent}%)`}</div>
                  <div>{assetType}</div>
                </Flex>
                <div>승인 시, 자동으로 주문완료되며 돌려받을 수 없습니다.</div>
              </div>
              <Flex>
                <div>
                  <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={() => {
                      setStep(step - 1);
                    }}>
                    취소
                  </button>
                </div>
                <div>
                  <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={handleClickPurchase}>
                    승인
                  </button>
                </div>
              </Flex>
            </div>
          )}
        </Content>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 10px;
  border-radius: 10px;
  min-width: 200px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
`;

const Content = styled.div`
  margin-top: 5px;
`;
